import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, useQuery } from "@apollo/client";
import { SCRAPPER_PARSE_URL_MUTATION } from "../graphql/auth";
import {
  GET_RECIPES,
  POST_RECIPE,
  GET_RECIPE_TAGS,
  POST_RECIPE_TAGS,
} from "../graphql/recipes";
import {
  GET_INGREDIENTS,
  ADD_INGREDIENT_MUTATION,
} from "../graphql/ingredients";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { navigate } from "gatsby";

import Recipe from "./recipe";
import Tagboxes from "./tagboxes";

function camalize(str) {
  return (
    str
      .toLocaleLowerCase("tr-TR")
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  );
}
function convertTr(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const Scrapper = () => {
  const [url, setUrl] = useState("");
  const [recipe, setRecipe] = useState(null);
  const [tags, setTags] = React.useState([]);
  const [showError, setShowError] = useState(false);
  // console.log('parser recipe', recipe);


  useEffect(() => {
    if (recipe && tags.length == 0) {
      let tagsfound = tagsdata.recipeTags.filter((s) => {
        return (
          recipe.recipetype.filter(
            (t, j) =>
              s.name.toLowerCase().indexOf(t.toLowerCase()) > -1 ||
              t.toLowerCase().indexOf(s.name.toLowerCase()) > -1
          ).length > 0
        );
      });
      setTags(tagsfound);
    }
  }, [recipe]);

  const { data: recipesdata } = useQuery(GET_RECIPES); // REFETCH
  const { data: tagsdata } = useQuery(GET_RECIPE_TAGS);
  const { data: ingData } = useQuery(GET_INGREDIENTS);

  const tagtoSuggest = tagsdata?.recipeTags?.filter((s) => s.name.toLowerCase().indexOf('AnaYemek'.toLowerCase()) > -1);
  console.log(`'recipesdata`, recipesdata);

  const [parseUrl, { loading, error }] = useMutation(
    SCRAPPER_PARSE_URL_MUTATION,
    {
      variables: { url },
      onCompleted: ({ ScrapperMutation }) => {
        const r = { ...ScrapperMutation };
        delete r["__typename"];
        setRecipe(r);
      },
      refetchQueries: [GET_RECIPES],
    }
  );
  const [postRecipe, { loading: loading2, error: error2 }] = useMutation(
    POST_RECIPE,
    {
      onCompleted: ({ PostRecipeMutation }) => {
        navigate("/app/recipes");
      },
      refetchQueries: [GET_RECIPES, GET_INGREDIENTS],
    }
  );
  const [postRecipeTags, { loading: loading3, error: error3 }] = useMutation(
    POST_RECIPE_TAGS,
    {
      onCompleted: ({ PostRecipeTagsMutation }) => {
        console.log("PostRecipeTagsMutation", PostRecipeTagsMutation);
      },
      refetchQueries: [GET_RECIPE_TAGS],
    }
  );
  const [AddIngredient, { loading: loading4, error: error4 }] = useMutation(
    ADD_INGREDIENT_MUTATION,
    {
      onCompleted: ({ AddIngredientMutation }) => {
      },
      // refetchQueries: [
      //   GET_WEEKLYPLANS, // DocumentNode object parsed with gql
      // ],
    }
  );

  function parse() {
    if(recipesdata.recipes.filter(s=> s.url == url).length > 0){
      alert('Already fetched!')
      return
    }
    try {
      parseUrl();
    } catch (error) {
      setShowError(true);
    } finally {
    }
  }

  async function SendRecipe() {
    // first save tags
    const tagsToCreate = tags
      .filter((t) => t.inputValue)
      .map((t) => t.inputValue);
    const tagstoAddToRecipe = [];
    for (const key in tagsToCreate) {
      let { data } = await postRecipeTags({
        variables: { data: { name: tagsToCreate[key] } },
      });
      const newId = data.createRecipeTag.recipeTag.id;
      tagstoAddToRecipe.push(newId);
    }
    const totaltags = [
      ...tags.filter((t) => t.id).map((t) => t.id),
      ...tagstoAddToRecipe,
    ];
    // Attach ingredient objects
    var ingstoAttach = [];
    let ingredientsSoft = [...recipe.ingredients];
    for (const [i, ing] of recipe.ingredients.entries()) {
      for (const [j, list] of ing.list.entries()) {
        var find = ingData.ingredients.filter((s) => s.name == list.Name);
        if (find.length > 0) {
          ingredientsSoft[i].list[j] = {
            ...ingredientsSoft[i].list[j],
            ingIds: [find[0].id],
          };
          ingstoAttach.push(find[0].id);
        } else {
          const ingRes = await AddIngredient({
            variables: {
              data: {
                name: list.Name,
                aliases:[list.Name],
                key: convertTr(camalize(list.Name)),
                image: list.ImagePathLarge || list.ImagePathSmall || list.Media,
                ymkId: list.ymkId,
              },
            },
          });
          const ingId = ingRes.data.createIngredient.ingredient.id
          ingstoAttach.push(ingId);
        }
      }
    }

    const recipeData = await postRecipe({
      variables: {
        data: {
          ...recipe,
          recipe_tags: totaltags,
          ingredientObjs: ingstoAttach,
        },
      },
    });
  }

  if (!tagsdata) return null;
  // console.log("tags", tags);

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          my: 4,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          required
          id="url"
          name="url"
          label="Url"
          onChange={(event) => setUrl(event.target.value)}
          sx={{ width: 700, marginRight: "20px" }}
        />
        <Button
          disabled={loading}
          onClick={(event) => {
            parse(event);
          }}
          sx={{ my: 1 }}
        >
          Parse
        </Button>
      </Box>

      {recipe && (
        <Box
          sx={{
            my: 4,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <Tagboxes
              data={tagsdata.recipeTags}
              value={tags}
              setValue={(val) => {
                console.log("val", val);
                setTags(val);
              }}
            />
            <Typography variant="h9" noWrap component="div">
              Suggested tags:{" "}
              {recipe.recipetype.map((s) => {
                return (
                  <Button
                    key={s}
                    onClick={() => {
                      setTags([
                        ...tags,
                        {
                          inputValue: s,
                          name: 'Add "' + s + '"',
                        },
                      ]);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    {s}
                  </Button>
                );
              })}
              {tagtoSuggest.map((s,i) => {
                return (
                  <Button
                    key={'tg'+i}
                    onClick={() => {
                      setTags([
                        ...tags,
                        s,
                      ]);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    {s.name}
                  </Button>
                );
              })}
            </Typography>
          </div>
        </Box>
      )}
      {recipe && <Recipe data={recipe} />}
      {(loading || loading2) && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          disabled={!recipe}
          onClick={(event) => {
            SendRecipe(event);
          }}
          sx={{ my: 1 }}
          style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            width: "100%",
          }}
        >
          Save
        </Button>
      </div>
    </Container>
  );
};

export default Scrapper;
