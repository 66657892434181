import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { RenderPlanImages } from "../screens/mealplans";
import { navigate } from "gatsby";
import { getUser } from "../services/auth";
import { DateTime } from "luxon";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ShowEventDialog({
  open,
  handleClose,
  id,
  wData,
  cDatas,
}) {
  const user = getUser();
  const weeklyplanid = id.split("-")[1];
  const plan = wData.weeklyplans.filter((s) => s.id == weeklyplanid)[0];
  let event = null;
  cDatas?.calendars.forEach((c) => {
    const cEvents = c.events.filter((c) => c.planId == plan.id)[0];
    event = cEvents.events.filter((d) => d.id == id)[0];
  });
  const meals = plan.recipes.filter((s) => event.meals.indexOf(s.id) > -1);
  const d = DateTime.fromISO(event.start).toFormat("HH:mm");

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div>{`Date: ${d}, ${meals.length} Meals`}</div>

          <div onClick={() => navigate("/app/mealplans/edit/" + plan.id)}>
            <Typography gutterBottom>WeeklyPlan: {plan.name}</Typography>
          </div>
        </div>
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ width: 500, height: 200 }}>
        <div style={{ marginBottom: 20 }}>
          {meals.map((m, i) => {
            return (
              <div
                key={i + "meal"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 20,
                  alignItems: "center",
                }}
                onClick={() => navigate("/app/recipe/" + m.id)}
              >
                <img
                  src={`${m.imageSrc}?w=248&fit=crop&auto=format`}
                  srcSet={`${m.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt=""
                  loading="lazy"
                  style={{
                    width: 70,
                    height: 50,
                    marginRight: 10,
                  }}
                />
                <div>{m.recipeTitle}</div>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
