function camalize(str) {
  return (
    str
      // .toLowerCase()
      .toLocaleLowerCase("tr-TR")
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  );
}

function convertTr(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function convertLineUnits(lineunitlist) {
  let conversionObj = {};
  const ingredientunits = {};
  let errors = [];

  lineunitlist.split(/\r?\n/).forEach((line) => {
    if (line.length == 0) return;
    if (line[0] == "#") return;

    if (line.indexOf(":") > -1) {
      // main conversion
      const asplit = line.split(":");
      conversionObj[asplit[1]] = asplit[2];
    } else {
      // use main conversion
      let newLine = line.toLocaleLowerCase("tr-TR");
      Object.keys(conversionObj).forEach((s, i) => {
        // newLine = newLine.replace(s, conversionObj[s])
        // newLine = newLine.replace(new RegExp("\\b"+s+"\\b","gi"), conversionObj[s]);
        var rx = new RegExp(
          "(^|\\s)" + s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") + "(?!\\S)",
          "gi"
        );
        newLine = newLine.replace(rx, "$1" + conversionObj[s]);
      });

      const lsplit = newLine.split(" ");
      // console.log('lsplit',lsplit);
      // lsplit [ '1', '200ml', 'Süt', 'Kreması', '210', 'gram' ]
      // lsplit [ '1', '200ml', 'Havuç', '80', 'gram' ]
      // lsplit [ '1', '200ml', 'Ahu', 'Dudu', '100', 'gram' ]
      // lsplit [ '' ]
      if (lsplit.length > 1) {
        const name = lsplit
          .slice(2, -2)
          .map((s) => s.toLocaleLowerCase("tr-TR"))
          .join(" ");
        const fullText = line.toLocaleLowerCase("tr-TR");
        let volume = lsplit[1];
        let unit = lsplit[lsplit.length - 1];
        let amount = lsplit[lsplit.length - 2];
        if (volume.indexOf("-") > -1) {
          if (volume.split("-")[1] == "ml") {
            let sUnit = parseFloat(volume.split("-")[0]);
            amount = parseFloat(amount) / sUnit; // new amount
            volume = "ml";
          }
        }
        if (ingredientunits.hasOwnProperty(name)) {
          const vList = ingredientunits[name].list.map((s) => s.volume);
          if (vList.indexOf(volume) > -1) {
            errors.push(
              `LineUnit ${name} already defined with ${lsplit[1]} ${
                lsplit[lsplit.length - 2]
              } ${unit} \n`
            );
          }
          ingredientunits[name] = {
            name,
            list: [
              ...ingredientunits[name].list,
              {
                fullText,
                volume,
                unit,
                amount,
              },
            ],
          };
        } else {
          ingredientunits[name] = {
            name,
            list: [
              {
                fullText,
                volume,
                unit,
                amount,
              },
            ],
          };
        }
      }
    }
  });
  if (errors.length > 0) {
    throw new Error(errors);
  }
  return { conversionObj, ingredientunits };
}

export function makeIngredientListFromRecipes(recipes) {
  // console.log('recipes',recipes);
  let ingredients = [];
  recipes.forEach((el) => {
      el.ingredients.forEach((r) => {
        if(r.list){
          r.list.forEach(s=>{
            ingredients.push({ recipe: el.id, ing: s.details })
          })
        }else{
         ingredients.push({ recipe: el.id, ing: r.details })
        }
      })
  });
  // { recipe: 10, ing: [ '1', 'adet', 'soğan' ] },
  // { recipe: 10, ing: [ '1', 'adet', 'yeşil biber' ] },
  // { recipe: 10, ing: [ '1/2', 'adet', 'kırmızı biber' ] },
  return ingredients;
}

export async function Pretty(data, conversionObj) {
  // data
  // { recipe: 10, ing: [ '1', 'adet', 'soğan' ] },
  // { recipe: 10, ing: [ '1', 'adet', 'yeşil biber' ] },
  // { recipe: 10, ing: [ '1/2', 'adet', 'kırmızı biber' ] },

  // PROBLEM [ '1/2', 'tatli', 'kasigi kırmızı biber' ] },
  const p = data.map((s) => [s.recipe, s.ing.join(" ")]);
  // console.log('p',p);
  // [ 10, '2 yemek kaşığı sıvı yağ' ],
  const stack = [];
  p.forEach((s) => {
    let ingStack = [];
    let ingString = s[1];
    Object.keys(conversionObj).forEach((t) => {
      let rx = new RegExp(
        "(^|\\s)" + t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") + "(?!\\S)",
        "gi"
      );
      if (ingString.search(rx) > 0 && ingStack.length < 2) {
        const i = ingString.split(t);
        ingStack.push(i[0].replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
        ingStack.push(t);
        ingStack.push(i[1].replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
      }
    });
    let stack1 = [s[0], ...ingStack];
    stack.push(stack1);
  });
  if (data.length != stack.length) {
    throw new Error("conversion mismatch!");
  }

  // log(stack)
  // [ 15, '2', 'yemek kaşığı', 'tereyağı' ],
  // [ 15, '1', 'çay kaşığı', 'tuz' ],
  // [ 15, '1/2', 'çay kaşığı', 'tarçın' ],
  // [ 15, '1/2', 'çay kaşığı', 'yenibahar' ],
  // [ 16, '2', 'adet orta boy', 'domates' ],
  // [ 16, '1', 'adet büyük boy', 'soğan' ],

  /// Creating Keys and units
  const newP = [];
  const units = [];
  stack.forEach((el) => {
    if (el.length > 2) {
      units.push(el[2]);
      const key = convertTr(camalize(el[3]));
      const amountSplit = el[1].split("-");
      newP[key] = {
        recipes: newP[key]?.recipes ? [...newP[key]?.recipes, el[0]] : [el[0]],
        orj: el[3],
        amount: newP[key]?.amount
          ? [
              ...newP[key].amount,
              {
                number: amountSplit[amountSplit.length - 1],
                unit: el[2],
              },
            ]
          : [
              {
                number: amountSplit[amountSplit.length - 1],
                unit: el[2],
              },
            ],
      };
    }
  });
  // console.log('newP',newP);
  // kuKonmaz: { recipes: [ 25 ], orj: 'kuşkonmaz', amount: [ [Object] ] },
  // limon: { recipes: [ 25 ], orj: 'limon', amount: [ [Object] ] },
  // mezgitFileto: { recipes: [ 26 ], orj: 'mezgit fileto', amount: [ [Object] ] },
  // iriEkilmiKarabiber: {
  //   recipes: [ 26 ],
  //   orj: 'iri çekilmiş karabiber',
  //   amount: [ [Object] ]
  // },

  // console.log('units',units);
  // units [
  //   'yemek kaşığı',   'gram',          'adet',          'adet',
  //   'adet',           'adet',          'çay kaşığı',    'çay kaşığı',
  //   'çay kaşığı',     'çay kaşığı',    'gram',          'yemek kaşığı',
  //   'çay bardağı',    'çay kaşığı',    'çay kaşığı',    'çay kaşığı',
  //   'tatlı kaşığı',   'kahve fincanı', 'adet',          'adet büyük boy',

  // Grouping
  let grouped = {};
  Object.keys(newP).forEach((el) => {
    let amountKeys = [];
    newP[el].amount.forEach((el1) => {
      const key = convertTr(camalize(el1.unit));
      amountKeys[key] = {
        orj: el1.unit,
        number: amountKeys[key]?.number
          ? eval(amountKeys[key].number) + eval(el1.number)
          : el1.number,
      };
    });

    grouped[el] = {
      key: el,
      recipes: newP[el]?.recipes,
      recipesLength: newP[el]?.recipes.length,
      orj: newP[el].orj,
      amount: Object.values(amountKeys).map((s) => ({
        number: s.number,
        unit: s.orj,
      })),
    };
  });

  const groupedSorted = Object.values(grouped).sort(
    (a, b) => a.recipesLength - b.recipesLength
  );
  // console.log('grouped',grouped);
  // {
  //   key: 'rendelenmiKaArPeyniri',
  //   recipes: [ 23, 24, 27 ],
  //   recipesLength: 3,
  //   orj: 'rendelenmiş kaşar peyniri',
  //   amount: [
  //     { number: 2, unit: 'su bardağı' },
  //     { number: '200', unit: 'gram' }
  //   ]
  // }
  // {
  //   key: 'mantar',
  //   recipes: [ 23, 24 ],
  //   recipesLength: 2,
  //   orj: 'mantar',
  //   amount: [ { number: '10', unit: 'adet' }, { number: '100', unit: 'gram' } ]
  // }

  var uniqueUnits = units.filter(onlyUnique);
  // console.log('uniqueUnits',uniqueUnits);
  // uniqueUnits [
  //   'yemek kaşığı',   'gram',           'adet',
  //   'çay kaşığı',     'çay bardağı',    'tatlı kaşığı',
  //   'kahve fincanı',  'adet büyük boy', 'dal',
  //   'su bardağı',     'adet orta boy',  'diş',
  //   'kilogram',       'çorba kaşığı',   'tutam',
  //   'küçük boy kase', 'ml.',            'demet',
  //   'paket',          'dilim',          'yaprak',
  //   'çay',            'kutu',           'kase',
  //   'litre',          'avuç',           'çimdik',
  //   'şişe',           'tatlı',          'kg.',
  //   'kepçe',          'bağ',            'adet küçük boy'
  // ]

  return [groupedSorted, uniqueUnits];
}

export function groupSameUnits(dataset, akey = "unit", bkey = "amount") {
  let dataset2 = {};
  Object.keys(dataset).forEach((el) => {
    // console.log('dataset[el]',dataset[el]);
    // dataset[el] {
    //   key: 'zeytinyaI',
    //   recipes: [
    //     24, 16, 21, 22, 23,
    //     24, 25, 26, 27
    //   ],
    //   orj: [ 'sızma zeytinyağı', 'zeytinyağı' ],
    //   amount: [
    //     { number: '6', unit: 'yemek kaşığı', alias: '10 ml' },
    //     { number: 20, unit: 'yemek kaşığı', alias: '10 ml' },
    //     { number: '1/2', unit: 'çay bardağı', alias: '100 ml' }
    //   ],
    //   alias: 'zeytinyağı',
    //   recipesLength: 9
    // }
    const amountFix = dataset[el].amount.map((s) => {
      let sNumber = parseFloat(String(s.number).replace(",", "."));
      let sAlias = s.alias;
      if (s.alias.indexOf("-") > -1) {
        if (s.alias.split("-")[1] == "ml") {
          let sUnit = parseFloat(s.alias.split("-")[0]); // 200
          sNumber = sNumber * sUnit; // new amount
          sAlias = "ml";
        }
        if (s.alias.split("-")[1] == "gram") {
          let sUnit = parseFloat(s.alias.split("-")[0]); // 1000
          sNumber = sNumber * sUnit; // new amount
          sAlias = "gram";
        }
      }
      return {
        number: sNumber,
        alias: sAlias,
      };
    });
    // console.log('amountFix', amountFix);

    let amountKeys = {};
    amountFix.forEach((el1) => {
      const key = convertTr(camalize(el1[akey]));
      amountKeys[key] = {
        orj: el1[akey],
        number: amountKeys[key]?.number
          ? eval(amountKeys[key].number) + eval(el1.number)
          : el1.number,
      };
    });
    // console.log('amountKeys',amountKeys);
    // amountKeys {
    //   adet: { orj: 'adet', number: 54.5 },
    //   ml: { orj: 'ml', number: 700 },
    //   dilim: { orj: 'dilim', number: 3 },
    //   gram: { orj: 'gram', number: 1500 }
    // }

    // Total of ml, gram etc...
    let totalKeys = {};
    Object.keys(amountKeys).forEach((k) => {
      const s = { ...amountKeys[k] };
      const split = s.orj.split(" ");
      if (split.length > 1) {
        if (split[1] == "ml" || split[1] == "gram" || split[1] == "kilogram") {
          const number = parseFloat(split[0].replace(",", "."));
          const mulp = number * parseFloat(s.number);
          if (totalKeys.hasOwnProperty(split[1])) {
            totalKeys[split[1]] = {
              ...totalKeys[split[1]],
              number: totalKeys[split[1]].number + mulp,
            };
          } else {
            totalKeys[split[1]] = {
              orj: split[1],
              number: mulp,
            };
          }
          delete amountKeys[k];
        }
      }
    });
    const merged = { ...amountKeys, ...totalKeys };
    // console.log('merged');
    // { ml: { orj: 'ml', number: 17.5 } }

    dataset2[el] = {
      ...dataset[el],
      [bkey]: Object.values(merged),
    };
  });
  return dataset2;
}

export function fullConversion(mergedgroupedUnits) {
  const convertedAmounts = mergedgroupedUnits.map((s) => {
    if (!s.hasOwnProperty("definedUnits"))
      return { ...s, convertedAmounts: s.amount };
    const definedUnits = {}; // key value
    s.definedUnits.forEach((d) => {
      const sp = d.volume.split("-");
      const u = sp.length > 1 ? sp[1] : sp[0]; // adet or ml
      if (definedUnits.hasOwnProperty(u)) {
        definedUnits[u] = [...definedUnits[u], d];
      } else {
        definedUnits[u] = [d];
      }
    });
    const convertedAmounts = s.amount.map((f) => {
      if (f.orj == "gram")
        return {
          // IMPORTANT FIX
          orj: f.orj,
          number: f.number,
        };
      const selectedUnit = definedUnits[f.orj][0];
      const v = parseFloat(selectedUnit.volume.split("-")[0]) || 1; // volume unit
      const m = parseFloat(selectedUnit.amount); // mass unit
      const density = m / v;
      const number = f.number;
      const selectedAmount = typeof number == "string" ? eval(number) : number;
      const reqAm = parseFloat(selectedAmount);
      const newM = reqAm * density;
      return {
        orj: selectedUnit.unit,
        number: newM,
      };
    });
    return {
      ...s,
      convertedAmounts,
    };
  });

  const calculateTotalAmount = convertedAmounts.map((c) => {
    const total = {};
    c.convertedAmounts.forEach((s) => {
      if (total[s.orj]) {
        total[s.orj] = {
          orj: s.orj,
          number: parseFloat(total[s.orj].number) + parseFloat(s.number),
        };
      } else {
        total[s.orj] = {
          orj: s.orj,
          number: parseFloat(s.number),
        };
      }
    });
    return {
      ...c,
      totalAmounts: Object.values(total),
    };
  });

  return calculateTotalAmount;
}

function Wait(time = 1000) {
  return new Promise((s) => {
    setTimeout(() => {
      s();
    }, time);
  });
}

export function totalIngAmount(
  prettySorted,
  ingredientsMatch,
  conversionObj,
  unitListConvertedJson
) {
  // prettySorted Comes From All Recipes => NO NAME COVERSION YET
  /// Add Aliases => "orta yağlı kuzu kıyma": => "kuzu kıyma"
  const prettyGotAlias = prettySorted.map((p) => {
    if (ingredientsMatch.hasOwnProperty(p.orj)) {
      const alias = ingredientsMatch[p.orj];
      return {
        ...p,
        alias,
      };
    } else {
      throw new Error(`${p.orj} is not in ingredientsMatch list!`);
    }
  });
  // log(JSON.stringify(prettyGotAlias, null, 4));
  // {
  //   "key": "tavukGSu",
  //   "recipes": [
  //       10,
  //       12,
  //       25
  //   ],
  //   "recipesLength": 3,
  //   "orj": "tavuk göğsü",
  //   "amount": [
  //       {
  //           "number": "300",
  //           "unit": "gram"
  //       },
  //       {
  //           "number": 2,
  //           "unit": "adet"
  //       }
  //   ],
  //   "alias": [
  //       "tavuk bonfile",
  //       "piliç bonfile"
  //   ]
  // },

  //Grouping based on aliases
  const prettyGrouped = {};
  prettyGotAlias.forEach((p) => {
    const newkey = convertTr(camalize(p.alias[0]));
    if (prettyGrouped.hasOwnProperty(newkey)) {
      const recipes = [...prettyGrouped[newkey].recipes, ...p.recipes];
      prettyGrouped[newkey] = {
        key: newkey,
        recipes: recipes,
        orj: p.alias[0],
        amount: [...prettyGrouped[newkey].amount, ...p.amount],
        alias: [...p.alias, p.orj].filter(onlyUnique),
        recipesLength: recipes.length,
      };
    } else {
      prettyGrouped[newkey] = {
        key: newkey,
        recipes: p.recipes,
        orj: p.alias[0],
        amount: [...p.amount],
        alias: [...p.alias, p.orj].filter(onlyUnique),
        recipesLength: p.recipes.length,
      };
    }
  });
  // log(JSON.stringify(prettyGrouped, null, 4));
  // "tavukBonfile": {
  //   "key": "tavukBonfile",
  //   "recipes": [
  //       11,
  //       10,
  //       12,
  //       25
  //   ],
  //   "orj": "tavuk bonfile",
  //   "amount": [
  //       {
  //           "number": "500",
  //           "unit": "gram"
  //       },
  //       {
  //           "number": "300",
  //           "unit": "gram"
  //       },
  //       {
  //           "number": 2,
  //           "unit": "adet"
  //       }
  //   ],
  //   "alias": [
  //       "tavuk bonfile",
  //       "piliç bonfile",
  //       "tavuk göğsü"
  //   ],
  //   "recipesLength": 4
  // },

  // Attaching unit aliases
  const amountUnitsAlias = Object.values(prettyGrouped).map((p) => {
    const amounts = p.amount.map((a) => {
      if (conversionObj.hasOwnProperty(a.unit)) {
        return {
          ...a,
          alias: conversionObj[a.unit],
        };
      }
      return a;
    });
    return {
      ...p,
      amount: amounts,
    };
  });

  // log(JSON.stringify(amountUnitsAlias, null, 4));
  // [
  //   {
  //       "key": "domates",
  //       "recipes": [
  //           10,
  //           36,
  //           37,
  //           57,
  //           58,
  //           23,
  //           66,
  //           80,
  //           30,
  //           34,
  //           41,
  //           48,
  //           75,
  //           81,
  //           11,
  //           16,
  //           24,
  //           31,
  //           32,
  //           39,
  //           68,
  //           77,
  //           78,
  //           87,
  //           88,
  //           89,
  //           118,
  //           119,
  //           122,
  //           124,
  //           127,
  //           131,
  //           133
  //       ],
  //       "orj": "domates",
  //       "amount": [
  //           {
  //               "number": "1",
  //               "unit": "adet",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "1",
  //               "unit": "adet",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "3",
  //               "unit": "adet",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "4",
  //               "unit": "adet",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "1",
  //               "unit": "su bardağı",
  //               "alias": "200-ml"
  //           },
  //           {
  //               "number": 2.5,
  //               "unit": "su bardağı",
  //               "alias": "200-ml"
  //           },
  //           {
  //               "number": 13,
  //               "unit": "adet",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": 5,
  //               "unit": "adet büyük boy",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "2",
  //               "unit": "adet orta boy",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "3",
  //               "unit": "dilim",
  //               "alias": "dilim"
  //           },
  //           {
  //               "number": 25.5,
  //               "unit": "adet",
  //               "alias": "adet"
  //           },
  //           {
  //               "number": "1,5",
  //               "unit": "kg.",
  //               "alias": "1000 gram"
  //           }
  //       ],
  //       "alias": [
  //           "domates"
  //       ],
  //       "recipesLength": 33
  //   },
  //   {

  const groupedUnits = groupSameUnits(amountUnitsAlias, "alias", "amount");
  // log('groupedUnits', JSON.stringify(groupedUnits, null, 4));
  // "orj": "domates",
  // "amount": [
  //     {
  //         "orj": "adet",
  //         "number": 54.5
  //     },
  //     {
  //         "orj": "ml",
  //         "number": 700
  //     },
  //     {
  //         "orj": "dilim",
  //         "number": 3
  //     },
  //     {
  //         "orj": "gram",
  //         "number": 1500
  //     }
  // ],

  // merge with unitListConvertedJson data
  const mergedgroupedUnits = Object.values(groupedUnits).map((s) => {
    // console.log('s.orj',s.orj);
    if (unitListConvertedJson.hasOwnProperty(s.orj)) {
      return {
        ...s,
        definedUnits: unitListConvertedJson[s.orj].list,
      };
    } else {
      return s;
    }
  });

  // log('mergedgroupedUnits', JSON.stringify(mergedgroupedUnits, null, 4));
  //   {
  //     "key": "domates",
  //     "recipes": [
  //         10,
  //         23,
  //         11,
  //         16,
  //         24
  //     ],
  //     "orj": "domates",
  //     "amount": [
  //         {
  //             "orj": "adet",
  //             "numbers": 8
  //         },
  //         {
  //             "orj": "ml",
  //             "number": 200
  //         }
  //     ],
  //     "alias": [
  //         "domates"
  //     ],
  //     "recipesLength": 5,
  //     "definedUnits": [
  //         {
  //             "fullText": "1 adet domates 130 gram",
  //             "volume": "adet",
  //             "unit": "gram",
  //             "amount": "130"
  //         },
  //         {
  //             "fullText": "1 su bardağı domates 300 gram",
  //             "volume": "200-ml",
  //             "unit": "gram",
  //             "amount": "300"
  //         }
  //     ]
  // },

  // check ing has defined units and conversion is possible or not
  const unitErrors = [];
  const ingredientsthathasdifferentunits = mergedgroupedUnits.filter((s) => {
    const units = s.amount.map((s) => s.orj).filter((s) => s != "gram"); // common
    if (s.definedUnits) {
      const definedUnits = s.definedUnits.map((s) => s.volume);
      if (units.filter((f) => definedUnits.indexOf(f) < 0).length > 0) {
        let unitsErrors = units.filter((f) => definedUnits.indexOf(f) < 0);
        // console.log('units', units);
        // console.log('definedUnits', definedUnits);
        // console.log('s', s);
        unitErrors.push(
          `${s.orj || s.key} doesnt have ${unitsErrors.join(",")}`
        );
        return true;
      } else {
        return false;
      }
    } else {
      // check all has gram
      if (s.amount.map((s) => s.orj).filter((s) => s == "gram").length < 1) {
        // console.log('s', s);
        unitErrors.push(
          `--${
            s.orj || s.key
          }-- doesnt have defined units and gram option: ${s.amount.map(
            (s) => s.orj
          )}`
        );
        return true;
      }
    }
    return false;
  });
  if (ingredientsthathasdifferentunits.length > 0) {
    log("unitErrors", unitErrors);
    throw new Error("this has unknown line units!");
  }

  // Now full conversion
  const calculateTotalAmount = fullConversion(mergedgroupedUnits);
  return calculateTotalAmount;
}