import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { getUser } from "../services/auth";
import { DateTime } from "luxon";
import { GET_WEEKLYPLANS } from "../graphql/mealplan";
import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ShowEventDialog({
  open,
  plans,
  handleClose,
  handleAdd,
  date,
}) {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        Weekly Plan for {DateTime.fromJSDate(date.date).toFormat("DD")}
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ width: 500, height: 200 }}>
        <div style={{ marginBottom: 20 }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={plans}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Meal Weekly Plan" />
            )}
            onChange={(event, newValue) => {
              handleAdd({ ...newValue, ...date });
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
