import React, { Component, useState } from "react";
import { navigate } from "gatsby";
import { handleLogin, isLoggedIn } from "../services/auth";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMutation} from "@apollo/client";
import { LOGIN_MUTATION } from "../graphql/auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [login, { loading, error }] = useMutation(LOGIN_MUTATION, {
    variables: { email, password },
    onCompleted: ({ login }) => {
      handleLogin(login)
      navigate(`/`);
    },
  })

  if (error) {
    console.log("error", error);
  }

  if (isLoggedIn()) {
    navigate(`/`);
  }
  return (
    <Container maxWidth="xs">
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          id="email"
          name="email"
          label="Email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          required
          id="password"
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
        disabled={loading}
          onClick={(event) => {
            login(event);
          }}
          sx={{ my: 2, display: "block" }}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
}

export default Login;
