let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
    // slotDuration: '3 day',
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:00:00",
    // daysOfWeek: [ 1, 2, 3, 4 ]
  },

  {
    groupId: "blueEvents", // recurrent events in this group move together
    title: "event1",
    start: "2022-02-04",
    end: "2022-02-10",
  },
];

export function createEventId() {
  return String(eventGuid++);
}
