import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { DateTime } from "luxon";
import { useQuery } from "@apollo/client";
import { GET_RECIPES } from "../../graphql/recipes";

const weekdays = [
  { label: "Pazartesi", slug: "mon" },
  { label: "Sali", slug: "tue" },
  { label: "Çarsamba", slug: "wed" },
  { label: "Persembe", slug: "thu" },
  { label: "Cuma", slug: "fri" },
  { label: "Cumartesi", slug: "sat" },
  { label: "Pazar", slug: "sun" },
];
function createBoardData(addedMeals, weekTags, ogunTags, plan) {
  let quoteMap = {};
  if (plan) {
    plan.baskets.forEach((s) => {
      let key = `${s.day}.${s.time.name}`;
      const m = plan.recipes.filter((p) => s.meals.indexOf(p.id) > -1);
      quoteMap[key] = m;
    });
  } else if (addedMeals) {
    let index = 0;
    weekTags.forEach((w, i) => {
      ogunTags.forEach((o, j) => {
        quoteMap[String(w + "." + o.name)] = [
          addedMeals[index],
          addedMeals[index + 1],
          addedMeals[index + 2],
        ];
        index += 1;
      });
    });
  } else {
    weekTags.forEach((w, i) => {
      ogunTags.forEach((o, j) => {
        quoteMap[String(w + "." + o.name)] = [];
      });
    });
  }
  return quoteMap;
}

export default function Stage1({ setBoardData, plan, setDummyData }) {
  const { loading, error, data } = useQuery(GET_RECIPES);

  const [planName, setPlanName] = useState(plan?.name || "");
  const [planNameSet, setPlanNameSet] = useState(plan?.name || null);

  const [ogunValue, setOgunValue] = useState("");
  const [ogunTimeValue, setogunTimeValue] = useState(new Date());

  const [ogunTags, setOgunTags] = useState(plan?.times || []);
  if (plan) {
    let recipes = [];
    plan.recipes.forEach((el) => {
      recipes["_" + String(el.id)] = el;
    });
  }
  const [weekTags, setweekTags] = useState(plan?.weekdays || []);

  let weekTagsMapped = weekTags.map((s) => weekdays.filter(k=> k.slug == s)[0]['label']);

  function addOgun() {
    if (ogunValue.length == 0) return;

    const hour = DateTime.fromJSDate(ogunTimeValue).toLocaleString(
      DateTime.TIME_SIMPLE
    );
    const ogun = {
      name: ogunValue,
      time: hour,
    };
    setOgunTags([...ogunTags, ogun]);
  }
  function onOgunChange(val) {
    setOgunValue(val.target.value);
  }
  function deleteTag(i) {
    setOgunTags(ogunTags.filter((s) => s.name != ogunTags[i].name));
  }
  function deleteWeekTag(i) {
    setweekTags(weekTags.filter((s) => s != weekTags[i]));
  }
  function onweekdayTagChange(newValue) {
    if (newValue && weekTags.filter((s) => s == newValue.slug) < 1)
      setweekTags([...weekTags, newValue.slug]);
  }

  // let total = 0;
  // addedMeals
  //   .map((s) => parseFloat(s.recipe?.cost || 0) * s.times)
  //   .forEach((s) => (total += s));

  function onCreateBoard() {
    const quoteMap = createBoardData(null, weekTags, ogunTags, plan);
    setBoardData({
      name: planNameSet,
      times: ogunTags,
      board: quoteMap,
      weekTags,
    });
  }

  function onDummyData() {
    const times = [
      { name: "sabah", time: "9:00 AM" },
      { name: "oglen", time: "1:00 PM" },
      { name: "aksam", time: "7:31 PM" },
    ];
    const weekTags = weekdays.map((w) => w.slug);
    const quoteMap = createBoardData(data.recipes, weekTags, times);
    setDummyData({
      name: "t" + parseFloat(new Date().getTime() / 1000).toFixed(0),
      times: times,
      board: quoteMap,
      weekTags,
    });
  }

  if (!data) return null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg" style={{ padding: 10 }}>
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" noWrap component="div">
              Plan Name: {planNameSet}
            </Typography>
            <Button onClick={(event) => onDummyData()} sx={{ my: 1 }}>
              Load Data
            </Button>
          </Stack>

          <Stack
            direction="row"
            flexWrap={"wrap"}
            spacing={1}
            sx={{ p: 2, m: 2, border: "1px dashed grey" }}
          >
            <TextField
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              id="outlined-basic"
              label="Haftalik Plan Adı"
              variant="outlined"
            />
            <Button
              primary="true"
              onClick={(event) => setPlanNameSet(planName)}
              sx={{ my: 1 }}
            >
              Set Plan Name
            </Button>
          </Stack>
          <Stack spacing={1} sx={{ p: 2, m: 2, border: "1px dashed grey" }}>
            <Stack direction="row" flexWrap={"wrap"} spacing={1}>
              <TextField
                value={ogunValue}
                onChange={(val) => onOgunChange(val)}
                id="outlined-basic"
                label="Öğün Adı"
                variant="outlined"
                style={{ margin: 10 }}
              />
              <div style={{ margin: 10 }}>
                <TimePicker
                  label="Time"
                  value={ogunTimeValue}
                  onChange={(val) => setogunTimeValue(val)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <Button
                primary="true"
                onClick={(event) => addOgun()}
                sx={{ my: 1 }}
              >
                Add Time
              </Button>
            </Stack>
            <div>
              {ogunTags.map((s, i) => (
                <Chip
                  key={"chip" + i}
                  // label={<div><div>{s.name}</div><div>{s.time}</div></div>}
                  label={s.name + " " + s.time}
                  onDelete={() => deleteTag(i)}
                  style={{ margin: 5 }}
                />
              ))}
            </div>
          </Stack>

          {/* {ogunTags.length > 0 && (
            <Stack
              direction="row"
              spacing={1}
              sx={{ p: 2, m: 2, border: "1px dashed grey" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={data.recipes}
                getOptionLabel={(option) => option.recipeTitle}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tarif" />
                )}
                onChange={(event, newValue) => {
                  setmealtoAdd(newValue);
                }}
                value={mealtoAdd}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-label">Ogun</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOgun}
                  label="Ogun"
                  onChange={(evt) => setselectedOgun(evt.target.value)}
                >
                  {ogunTags.map((s, i) => (
                    <MenuItem key={"chipm" + i} value={i}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                primary="true"
                disabled={!mealtoAdd}
                onClick={(event) => onPressAddMeal()}
                sx={{ my: 1 }}
              >
                Add Meal
              </Button>
            </Stack>
          )} */}

          {/* <MealGrid /> */}
          {/* {addedMeals.length > 0 && (
            <Stack
              spacing={1}
              sx={{ p: 2, m: 2, border: "1px dashed grey" }}
              style={{
                height: 500,
                overflow: "scroll",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography variant="h9" noWrap component="div">
                    Recipe Title
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h9" noWrap component="div">
                    Ogun
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h9" noWrap component="div">
                    Times
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h9" noWrap component="div">
                    Delete
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              {addedMeals.map((m, i) => {
                if (!m.recipe) return null;
                return (
                  <div key={"grid" + i}>
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <img
                          src={m.recipe.imageSrc}
                          loading="lazy"
                          alt=""
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                          }}
                          sx={{ mx: 2 }}
                          onClick={() => navigate(`/app/recipe/` + m.recipe.id)}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="h9"
                          noWrap
                          component="div"
                          sx={{
                            my: 2,
                          }}
                          onClick={() => navigate(`/app/recipe/` + m.recipe.id)}
                        >
                          {m.recipe.recipeTitle}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="h9"
                          noWrap
                          component="div"
                          sx={{
                            my: 2,
                          }}
                        >
                          {m.ogun.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="h9"
                          noWrap
                          component="div"
                          sx={{
                            my: 2,
                          }}
                        >
                          {m.times}x
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteMeal(m.recipe)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Divider />
                  </div>
                );
              })}
            </Stack>
          )}
          <div>Cost: {total.toFixed(2)} TL</div> */}

          <Stack
            direction="row"
            flexWrap={"wrap"}
            spacing={1}
            sx={{ p: 2, m: 2, border: "1px dashed grey" }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={weekdays}
              getOptionLabel={(option) => option.label}
              sx={{ width: 300, marginBottom: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Weekdays" />
              )}
              onChange={(event, newValue) => {
                onweekdayTagChange(newValue);
              }}
            />
            {weekTagsMapped.map((s, i) => (
              <Chip
                key={"chip" + i}
                label={s}
                onDelete={() => deleteWeekTag(i)}
                style={{ margin: 5 }}
              />
            ))}
          </Stack>
          <Stack
            direction="row-reverse"
            spacing={1}
            sx={{ p: 2, m: 2, border: "1px dashed grey" }}
          >
            <Button
              variant="contained"
              disabled={
                !planNameSet || ogunTags.length < 1 || weekTags.length < 1
              }
              onClick={(event) => onCreateBoard()}
              sx={{ my: 1 }}
            >
              Create Board
            </Button>
          </Stack>
        </Box>
      </Container>
    </LocalizationProvider>
  );
}
