import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import PrivateRoute from "../components/privateRoute";
import Profile from "../screens/profile";
import Login from "../screens/login";
import Recipes from "../screens/recipes";
import Recipe from "../screens/recipe";
import addRecipe from "../screens/addRecipe";
import Calendar from "../screens/calendar";
import MealPlans from "../screens/mealplans";
import addMealPlan from "../screens/addMealPlan";
import editMealPlan from "../screens/editMealPlan";
import ingredients from "../screens/ingredients";
import ingredient from "../screens/ingredient";

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      {/* <PrivateRoute path="/app/scrapper" component={Scrapper} /> */}
      <PrivateRoute path="/app/recipes" component={Recipes} />
      <PrivateRoute path="/app/recipe/add" component={addRecipe} />
      <PrivateRoute path="/app/recipe/:slug" component={Recipe} />
      <PrivateRoute path="/app/calendar" component={Calendar} />
      <PrivateRoute path="/app/mealplans" component={MealPlans} />
      <PrivateRoute path="/app/mealplans/add" component={addMealPlan} />
      <PrivateRoute path="/app/mealplans/edit/:slug" component={editMealPlan} />
      <PrivateRoute path="/app/ingredients" component={ingredients} />
      <PrivateRoute path="/app/ingredient/:slug" component={ingredient} />
      <Login path="/app/login" />
    </Router>
  </Layout>
);

export default App;
