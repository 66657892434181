import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useQuery } from "@apollo/client";
import { GET_INGREDIENTS } from "../graphql/ingredients";
import LoadingPage from '../components/loadingPage'

const Ingredients = (props) => {
  const { loading, error, data } = useQuery(GET_INGREDIENTS);
  // console.log(`ing data`, data);

  if (loading) return <LoadingPage />;
  return (
    <Container maxWidth="lg" style={{ padding: 10 }}>
      <Box sx={{ display: { xs: "flex", md: "flex" } }}>
        <ImageList sx={{ width: 1600 }} cols={6} gap={8}>
          {data.ingredients.map((item) => (
            <ImageListItem
              key={item.id + "ings"}
              onClick={(event) => {
                event.preventDefault();
                navigate(`/app/ingredient/` + item.id);
              }}
            >
              {item.image ? <img
                src={`${item.image}?w=248&fit=crop&auto=format`}
                srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.name}
                loading="lazy"
              /> : <div style={{width:'100%', height:140, background:'#ccc' }}></div> }
              <ImageListItemBar
                title={item.name}
                subtitle={item.aliases ? item.aliases.join(",") : null}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Container>
  );
};

export default Ingredients;
