import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CALENDAR_EVENTS,
  EDIT_CALENDAR_MUTATION,
  GET_CALENDARS,
  ADD_CALENDAR_MUTATION,
} from "../graphql/calendar";
import { getUser } from "../services/auth";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs({ open, handleClose }) {
  const user = getUser();
  const [name, setName] = React.useState("");

  const { data: cDatas } = useQuery(GET_CALENDARS, {
    variables: { uid: user.id },
  });
  const [addCalendar, { loading }] = useMutation(ADD_CALENDAR_MUTATION, {
    onCompleted: (data) => {
      // console.log("data", data);
    },
    refetchQueries: [
      GET_CALENDARS, // DocumentNode object parsed with gql
    ],
  });

  function onPressAddCalendar() {
    addCalendar({
      variables: {
        data: { name, user: user.id },
      },
    });
  }
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        Add Calendar
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ width: 500, height: 300 }}>
        <Typography gutterBottom>No calendar is found. Create one.</Typography>

        <TextField
          required
          id="name"
          name="name"
          label="Name"
          onChange={(event) => setName(event.target.value)}
        />
        {loading && <span>Loading...</span>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onPressAddCalendar()}>
          Add Calendar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
