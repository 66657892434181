import React, {useState} from "react";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import IconButton from "@mui/material/IconButton";
import {
  GET_RECIPES,
} from "../../graphql/recipes";
import { useQuery } from "@apollo/client";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
export async function textToClipboard(list) {
  const lines = [];
  list.forEach((s, j) => {
    const amount = s.totalAmounts.map((k, l) => {
      return " " + parseFloat(k.number).toFixed(1) + " " + k.orj;
    });
    lines.push(s.orj + " " + amount);
  });
  const l = lines.join("\n");
  // console.log(l);
  copyTextToClipboard(l);
}

export default function Ingredients({ list }) {
  const { data: recipesdata } = useQuery(GET_RECIPES);
  const [extended, setExtended] = useState(false)

  function renderAmounts(s, j) {
    if (s.amount[0].orj == s.totalAmounts[0].orj) {
      return (
        <>
          (
          <span style={{ fontSize: 17 }}>
            {s.amount.map((k, l) => {
              return (
                <span key={l} style={{ marginRight: 5 }}>
                  {parseFloat(k.number).toFixed(1)} {k.orj}
                  {s.amount.length - 1 > l ? "," : ""}
                </span>
              );
            })}
          </span>
          )
        </>
      );
    }
    return (
      <>
        (
        <span style={{ fontSize: 17 }}>
          {s.amount.map((k, l) => {
            return (
              <span key={l} style={{ marginRight: 5 }}>
                {parseFloat(k.number).toFixed(1)} {k.orj}
                {s.amount.length - 1 > l ? "," : ""}
              </span>
            );
          })}
        </span>
        ) (
        <span style={{ fontSize: 10 }}>
          {s.totalAmounts.map((k, l) => {
            return (
              <span key={l}>
                {parseFloat(k.number).toFixed(1)} {k.orj}
                {s.amount.length - 1 > l ? "," : ""}
              </span>
            );
          })}
        </span>
        )
      </>
    );
  }
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  function renderRecipes(s,j){
    let recipesUnique = s.recipes.filter(onlyUnique);
    let recipes = recipesUnique.map(
      (f) => recipesdata.recipes.filter((g) => g.id == f)[0]
    );
    return (
      <div key={"r" + j} style={{padding:10, border:'1px dashed #ccc'}}>
        {recipes.map((R,i)=>{
          return (
            <div key={"R" + i} style={{display:'flex', flexDirection:'row', marginBottom:5}}>
              <img src={R.imageSrc} style={{ height: 30, marginRight: 10 }} />
              <div style={{width:300, whiteSpace:'nowrap', overflow:'hidden',textOverflow:'ellipsis'}}>{R.title}</div>
            </div>
          );
        }) }
      </div>
    );
  }
  return (
    <div>
      <h4>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div>
            Ingredients List
            <IconButton
              aria-label="delete"
              onClick={() => textToClipboard(list)}
            >
              <ContentPasteGoIcon />
            </IconButton>
          </div>
          <Button
            primary="true"
            onClick={(event) => setExtended(!extended)}
            sx={{ my: 1 }}
          >
            Extended
          </Button>
        </Stack>
      </h4>

      {list.map((s, j) => {
        return (
          <li key={j}>
            <>
              <strong>{s.orj}</strong> &nbsp;
              {renderAmounts(s, j)}
            </>
            {extended && renderRecipes(s,j)}
          </li>
        );
      })}
    </div>
  );
}
