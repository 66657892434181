import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_WEEKLYPLANS } from "../graphql/mealplan";
import { EDIT_CALENDAR_MUTATION, GET_CALENDARS } from "../graphql/calendar";
import { getUser } from "../services/auth";
import CalendarApp from "../components/fullCalendar";
import AddCalendarDialogs from "../modals/AddCalendar";
import ShowEventDialog from "../modals/ShowEvent";

export default function Calendar() {
  const user = getUser();
  const [eventClickState, setEventClickState] = useState(null);
  const { data: wData } = useQuery(GET_WEEKLYPLANS, {
    variables: { uid: user.id },
  });
  const { data: cDatas } = useQuery(GET_CALENDARS, {
    variables: { uid: user.id },
  });
  const calendar = cDatas?.calendars.length > 0 ? cDatas.calendars[0] : null;
  const backendCalendarUrl = `${process.env.API_URL}/calendars/${calendar?.id}/ics`
  const [updateCalendar] = useMutation(EDIT_CALENDAR_MUTATION, {
    onCompleted: (data) => {
      // console.log("data", data);
    },
    refetchQueries: [
      GET_CALENDARS, // DocumentNode object parsed with gql
    ],
  });

  if (!wData || !cDatas) return null;

  function onPlanChange(plan) {
    const plans = [];
    const dbEvents = plan.map((r) => {
      plans.push(r.id);
      return { planId: r.id, events: r.events };
    });
    updateCalendar({
      variables: {
        data: { events: dbEvents, weeklyplans: plans },
        id: calendar.id,
      },
    });
  }
  function onEventClick(id) {
    setEventClickState(id);
  }
  return (
    <div>
      <CalendarApp
        name={calendar?.name || null}
        plans={wData.weeklyplans}
        calendars={calendar || []}
        onPlanAdd={(p) => onPlanChange(p)}
        onPlanRemoved={(p) => onPlanChange(p)}
        eventClick={(e) => onEventClick(e)}
        backendCalendarUrl={backendCalendarUrl}
      />
      {cDatas?.calendars.length < 1 && (
        <AddCalendarDialogs
          open={true}
          handleClose={() => console.log("close")}
        />
      )}
      {eventClickState && (
        <ShowEventDialog
          open={true}
          wData={wData}
          cDatas={cDatas}
          id={eventClickState}
          handleClose={() => setEventClickState(null)}
        />
      )}
    </div>
  );
}
