import React, { useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import { RenderPlanImages } from "../screens/mealplans";
import { DateTime } from "luxon";
import ShowAddEvent from "../modals/ShowAddEvent";

const week = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
};

function renderEventContent(eventInfo) {
  return (
    <div className="demo-app-event">
      <b>{eventInfo.event.title.substring(0, 5)}... </b>
      <i>({eventInfo.timeText})</i>
    </div>
  );
}

export default class CalendarApp extends React.Component {
  calendarRef = React.createRef();
  state = {
    currentEvents: [],
    addedPlans: [],
    availablePlans: this.props.plans || [],
    dateSelected: false
  };

  componentDidMount() {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("data");
        let start = eventEl.getAttribute("data-start");
        let end = eventEl.getAttribute("data-end");
        return {
          title: title,
          id: id,
          start,
          end,
          slotDuration: "01:00",
          revertDuration: 0,
        };
      },
    });

    const api = this.calendarRef.current.getApi();
    if (this.props.calendars?.events?.length > 0) {
      let weeklyplans = [];
      this.props.calendars.weeklyplans.forEach((s) => {
        weeklyplans[`_${s.id}`] = s;
      });
      const addedPlans = this.props.calendars.events.map((el) => {
        const events = el.events.map((el2) => {
          const event = { ...el2, title: weeklyplans[`_${el.planId}`].name }; // update names
          api.addEvent(event);
          return event;
        });
        return {
          ...weeklyplans[`_${el.planId}`],
          events,
        };
      });
      const ids = this.props.calendars.weeklyplans.map((s) => s.id);
      this.setState({
        addedPlans,
        availablePlans: this.state.availablePlans.filter(
          (a) => ids.indexOf(a.id) < 0
        ),
      });
    }
  }

  handleeventReceive = (d) => {
    d.revert();
  };
  handleDateSelect = (d) => {
    this.setState({
      dateSelected: d
    })
  }
  handleDateAddEvent = (d) => {
    this.setState({
      dateSelected: false
    })

    const dropped = this.state.availablePlans.filter(
      (f) => f.id == d.id
    )[0];
    const startOfWeek = DateTime.fromISO(d.dateStr).startOf("week").toISO();
    const api = this.calendarRef.current.getApi();
    const events = [];
    dropped.weekdays.forEach((w, i) => {
      dropped.times.forEach((t, j) => {
        const eDate = `${DateTime.fromISO(startOfWeek)
          .plus({ days: week[w] })
          .toFormat("yyyy-MM-dd")} ${t.time}`;
        let meals = null;
        const basket = dropped.baskets
          .filter((f) => f.day == w)
          .filter((g) => JSON.stringify(t) == JSON.stringify(g.time));
        if (basket.length > 0) meals = basket[0].meals;

        const event = {
          groupId: dropped.id,
          id: `event-${dropped.id}-${i}${j}`,
          title: `${dropped.name}`,
          start: DateTime.fromFormat(eDate, "yyyy-MM-dd h:mm a").toISO(),
          meals,
        };
        api.addEvent(event);
        events.push(event);
      });
    });

    const planAdded = {
      ...dropped,
      events,
    };
    this.setState(
      {
        addedPlans: [...this.state.addedPlans, planAdded],
        availablePlans: this.state.availablePlans.filter(
          (a) => a.id != d.id
        ),
      },
      () => {
        this.props.onPlanAdd(this.state.addedPlans);
      }
    );
  }

  handleDrop = (d) => {
    const dropped = this.state.availablePlans.filter(
      (f) => f.id == d.draggedEl.id
    )[0];
    const startOfWeek = DateTime.fromISO(d.dateStr).startOf("week").toISO();
    const api = this.calendarRef.current.getApi();
    const events = [];
    dropped.weekdays.forEach((w, i) => {
      dropped.times.forEach((t, j) => {
        const eDate = `${DateTime.fromISO(startOfWeek)
          .plus({ days: week[w] })
          .toFormat("yyyy-MM-dd")} ${t.time}`;
        let meals = null;
        const basket = dropped.baskets
          .filter((f) => f.day == w)
          .filter((g) => JSON.stringify(t) == JSON.stringify(g.time));
        if (basket.length > 0) meals = basket[0].meals;

        const event = {
          groupId: dropped.id,
          id: `event-${dropped.id}-${i}${j}`,
          title: `${dropped.name}`,
          start: DateTime.fromFormat(eDate, "yyyy-MM-dd h:mm a").toISO(),
          meals,
        };
        api.addEvent(event);
        events.push(event);
      });
    });

    const planAdded = {
      ...dropped,
      events,
    };
    this.setState(
      {
        addedPlans: [...this.state.addedPlans, planAdded],
        availablePlans: this.state.availablePlans.filter(
          (a) => a.id != d.draggedEl.id
        ),
      },
      () => {
        this.props.onPlanAdd(this.state.addedPlans);
      }
    );
  };

  render() {
    return (
      <div className="demo-app">
        <div className="demo-app-sidebar">{this.renderSidebar()}</div>
        <div className="demo-app-main">
          <FullCalendar
            ref={this.calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView="dayGridMonth"
            editable={true}
            // selectable={true}
            droppable={false}
            firstDay={1}
            // selectMirror={true}
            // dayMaxEvents={true}
            // weekends={this.state.weekendsVisible}
            // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            dateClick={this.handleDateSelect}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            drop={(d) => this.handleDrop(d)}
            eventReceive={(d) => this.handleeventReceive(d)}
            // eventAdd={(d) => console.log("eventAdd", d)}
            // eventAdd={function(){}}
            eventChange={function (a) {
              console.log("a", a);
            }}
            // eventRemove={function(){}}
          />
          <div className="demo-app-sidebar-mobile">{this.renderSidebar()}</div>
        </div>
        {this.state.dateSelected && (
          <ShowAddEvent
            open={true}
            date={this.state.dateSelected}
            plans={this.state.availablePlans}
            handleAdd={this.handleDateAddEvent}
            handleClose={() => this.setState({ dateSelected: false })}
          />
        )}
      </div>
    );
  }

  renderSidebar() {
    return (
      <>
        {this.props.name && (
          <h5 style={{ margin: 10 }}>Calendar: {this.props.name}</h5>
        )}
        <div className="demo-app-sidebar-section" id="external-events">
          <h6>
            Available Weekly Plans ({this.state.availablePlans.length}) (drag&drop){" "}
          </h6>
          {this.state.availablePlans.map((r) =>
            this.renderSidebarEvent(r, false)
          )}
        </div>
        <div className="demo-app-sidebar-section">
          <h6>Calendar Added Plans ({this.state.addedPlans.length})</h6>
          {this.state.addedPlans.map((r) => this.renderSidebarEvent(r, true))}
        </div>
        <div className="demo-app-sidebar-section">
          <h6>
            <a href={this.props.backendCalendarUrl}>Add to Calendar(Ics)</a>
          </h6>
        </div>
      </>
    );
  }
  
  renderSidebarEvent(event, removable = false) {
    return (
      <div
        className="fc-event  mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
        data-id={event.id}
        id={event.id}
        key={event.id}
        title={event.name}
        data={event.id}
        data-start={event.start}
        data-end={event.end}
        style={{
          cursor: "pointer",
          marginBottom: 20,
        }}
      >
        <div>
          <strong
            style={{
              color: "#fff",
              marginBottom: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
            className="fc-h-event"
          >
            <span>{event.name}</span>

            {removable == true && (
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#fff",
                }}
                onClick={() => this.handleDeleteplan(event.id)}
              >
                X
              </button>
            )}
          </strong>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{event.weekdays?.join(",")}</div>
          </div>
          <RenderPlanImages recipes={event.recipes} />
        </div>
      </div>
    );
  }

  // handleDateSelect = (selectInfo) => {
  //   console.log(`selectInfo`, selectInfo);
  //   let title = prompt("Please enter a new title for your event");
  //   let calendarApi = selectInfo.view.calendar;

  //   calendarApi.unselect(); // clear date selection

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay,
  //     });
  //   }
  // };

  handleEventClick = (clickInfo) => {
    this.props.eventClick(clickInfo.event.id);
    // if (
    //   confirm(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove();
    // }
  };

  handleDeleteplan = (id) => {
    const api = this.calendarRef.current.getApi();
    const events = api.getEvents();
    const filtered = events
      .filter((f) => f._def.groupId == id)
      .forEach((R) => {
        R.remove();
      });
    const plantoAdd = this.props.plans.filter((f) => f.id == id)[0];
    this.setState(
      {
        availablePlans: [...this.state.availablePlans, plantoAdd],
        addedPlans: this.state.addedPlans.filter((a) => a.id != id),
      },
      () => {
        this.props.onPlanRemoved(this.state.addedPlans);
      }
    );
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };
}
