import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useMutation, useQuery } from "@apollo/client";
import { SCRAPPER_PARSE_URL_MUTATION } from "../graphql/auth";
import {
  GET_RECIPES,
  POST_RECIPE,
  GET_RECIPE_TAGS,
  POST_RECIPE_TAGS,
} from "../graphql/recipes";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Tagboxes from "./tagboxes";
import { navigate } from "gatsby";

// title, recipeTitle, details(json), ingredients(json), instructions(json list) imageSrc Url nutritions calori, video recipeTags
export default function RecipeEntry() {
  const [title, setTitle] = useState("");
  const [recipeTitle, setRecipeTitle] = useState("");
  const [tags, setTags] = useState([]);

  const [details1, setDetails1] = useState("");
  const [details2, setDetails2] = useState("");
  const [details3, setDetails3] = useState("");

  const [ingredientsStack, setIngredientsStack] = useState([]);
  const [ingredients1, setIngredients1] = useState("");
  const [ingredients2, setIngredients2] = useState("");
  const [ingredients3, setIngredients3] = useState("");

  const [instructions, setInstructions] = useState([]);
  const [instructionsText, setInstructionsText] = useState("");

  const [imageSrc, setImageSrc] = useState("");
  const [addedImg, setaddedImg] = useState("");

  const [url, setUrl] = useState("");
  const [nutritions, setNutritions] = useState([]);
  const [calori, setCalori] = useState("");
  const [video, setVideo] = useState("");
  const [recipeTags, setRecipeTags] = useState([]);

  const { data: recipesdata } = useQuery(GET_RECIPES); // REFETCH
  const { data: tagsdata } = useQuery(GET_RECIPE_TAGS);
  const [postRecipeTags, { loading: loading3, error: error3 }] = useMutation(
    POST_RECIPE_TAGS,
    {
      onCompleted: ({ PostRecipeTagsMutation }) => {
        console.log("PostRecipeTagsMutation", PostRecipeTagsMutation);
      },
      refetchQueries: [GET_RECIPE_TAGS],
    }
  );
  const [postRecipe, { loading: loading2, error: error2 }] = useMutation(
    POST_RECIPE,
    {
      onCompleted: ({ PostRecipeMutation }) => {
        navigate("/app/recipes");
      },
      refetchQueries: [GET_RECIPES],
    }
  );

  function controlForms() {
    if (title.length == 0) {
      alert("Title empty!");
      return;
    }
    if (recipeTitle.length == 0) {
      alert("recipeTitle empty!");
      return;
    }
    if (details1.length == 0) {
      alert("details1 empty!");
      return;
    }
    if (details2.length == 0) {
      alert("details2 empty!");
      return;
    }
    if (details3.length == 0) {
      alert("details3 empty!");
      return;
    }
    if (ingredientsStack.length == 0) {
      alert("ingredientsStack empty!");
      return;
    }
    if (instructions.length == 0) {
      alert("instructions empty!");
      return;
    }
    if (addedImg.length == 0) {
      alert("addedImg empty!");
      return;
    }
    if (imageSrc.length == 0) {
      alert("imageSrc empty!");
      return;
    }
    if (tags.length == 0) {
      alert("tags empty!");
      return;
    }
    return {
      title,
      recipeTitle,
      details: [
        {
          text: "KAÇ  KİŞİLİK",
          number: typeof details1 == "number" ? `${details1} kişilik` : details1,
        },
        {
          text: "HAZIRLAMA  SÜRESİ",
          number: typeof details1 == "number" ? `${details2} dakika` : details2,
        },
        {
          text: "PİŞİRME  SÜRESİ",
          number: typeof details1 == "number" ? `${details3} dakika` : details3,
        },
      ],
      /// ingredients changed
      ingredients: ingredientsStack.map((f) => {return {details: f,ingIds:[]};}),
      instructions,
      imageSrc,
      url,
      nutritions,
      calori,
      video,
    };
  }
  async function SendRecipe() {
    if (controlForms()) {
      const recipe = controlForms()
      // first save tags
      const tagsToCreate = tags
        .filter((t) => t.inputValue)
        .map((t) => t.inputValue);
      const tagstoAddToRecipe = [];
      for (const key in tagsToCreate) {
        let { data } = await postRecipeTags({
          variables: { data: { name: tagsToCreate[key] } },
        });
        const newId = data.createRecipeTag.recipeTag.id;
        tagstoAddToRecipe.push(newId);
      }
      const totaltags = [
        ...tags.filter((t) => t.id).map((t) => t.id),
        ...tagstoAddToRecipe,
      ];

      const recipetodb = { ...recipe, recipe_tags: totaltags }
      console.log('recipetodb',recipetodb);
      await postRecipe({
        variables: { data: recipetodb },
      });
    }
  }

  function addAnotherIngredient() {
    const ing = [ingredients1, ingredients2, ingredients3];
    const ingClone = [...ingredientsStack];
    ingClone.push(ing);
    setIngredientsStack(ingClone);
    setIngredients1("");
    setIngredients2("");
    setIngredients3("");
  }
  function addAnotherInstructions() {
    const ing = {
      text: instructionsText,
    };
    const ingClone = [...instructions];
    ingClone.push(ing);
    setInstructions(ingClone);
    setInstructionsText("");
  }

  const ingStack = [...ingredientsStack, ""];
  const instructionsStack = [...instructions, ""];

  if (!tagsdata) return null;

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          my: 4,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          spacing={1}
          sx={{
            p: 2,
            m: 2,
            border: "1px dashed grey",
          }}
        >
          <TextField
            required
            id="title"
            name="title"
            label="Ana Baslik"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            sx={{ width: "100%" }}
          />
          <TextField
            required
            id="recipeTitle"
            name="recipeTitle"
            label="Baslik"
            value={recipeTitle}
            onChange={(event) => setRecipeTitle(event.target.value)}
            sx={{ width: "100%" }}
          />

          <Tagboxes
            data={tagsdata.recipeTags}
            // selected={recipe.recipetype}
            value={tags}
            setValue={(val) => setTags(val)}
          />

          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
            }}
          >
            <TextField
              id="kackisilik"
              name="kackisilik"
              label="Kac Kisilik(4 kişilik)"
              value={details1}
              onChange={(event) => setDetails1(event.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="HAZIRLAMASÜRESİ"
              name="HAZIRLAMASÜRESİ"
              label="Hazirlama Suresi (20 dakika)"
              value={details2}
              onChange={(event) => setDetails2(event.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="PİŞİRME  SÜRESİ"
              name="PİŞİRME  SÜRESİ"
              label="Pisirme Suresi(10 dakika)"
              value={details3}
              onChange={(event) => setDetails3(event.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>

          <Typography
            variant="h6"
            noWrap
            component="div"
          >
            Ingredients ({ingredientsStack.length})
          </Typography>
          {ingStack.map((r, i) => {
            if (typeof r == "object") {
              return (
                <div key={"ing" + i} style={{}}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      p: 2,
                      border: "1px dashed grey",
                    }}
                  >
                    <Typography
                      variant="h7"
                      noWrap
                      component="div"
                      sx={{ width: "100%" }}
                    >
                      {r[0]}
                    </Typography>
                    <Typography
                      variant="h7"
                      noWrap
                      component="div"
                      sx={{ width: "100%" }}
                    >
                      {r[1]}
                    </Typography>
                    <Typography
                      variant="h7"
                      noWrap
                      component="div"
                      sx={{ width: "100%" }}
                    >
                      {r[2]}
                    </Typography>
                  </Stack>
                </div>
              );
            }
            return (
              <div key={"ing" + i} style={{}}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    p: 2,
                    border: "1px dashed grey",
                  }}
                >
                  <TextField
                    id="ing1"
                    name="ing1"
                    label="2"
                    value={ingredients1}
                    onChange={(event) => setIngredients1(event.target.value)}
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    id="ing2"
                    name="ing2"
                    label="su bardagi"
                    value={ingredients2}
                    onChange={(event) => setIngredients2(event.target.value)}
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    id="ing3"
                    name="ing3"
                    label="ince bulgur"
                    value={ingredients3}
                    onChange={(event) => setIngredients3(event.target.value)}
                    sx={{ width: "100%" }}
                  />
                </Stack>
              </div>
            );
          })}
          <Button
            onClick={(event) => {
              addAnotherIngredient(event);
            }}
            sx={{ my: 1, width: "100%" }}
          >
            Add another
          </Button>

          <Typography
            variant="h6"
            noWrap
            component="div"
          >
            Instructions  ({instructions.length})
          </Typography>
          {instructionsStack.map((r, i) => {
            if (typeof r == "object") {
              return (
                <div key={"ins" + i} style={{}}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      p: 2,
                      border: "1px dashed grey",
                    }}
                  >
                    <Typography
                      variant="h7"
                      noWrap
                      component="div"
                      sx={{ width: "100%" }}
                    >
                      {r.text}
                    </Typography>
                  </Stack>
                </div>
              );
            }
            return (
              <div key={"ins" + i} style={{}}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    p: 2,
                    border: "1px dashed grey",
                  }}
                >
                  <TextField
                    id="ins1"
                    name="ins1"
                    label="Hamurunuz güzelce kıvam aldıktan sona, fotoğraftaki gibi şekil verin."
                    value={instructionsText}
                    onChange={(event) =>
                      setInstructionsText(event.target.value)
                    }
                    sx={{ width: "100%" }}
                  />
                </Stack>
              </div>
            );
          })}
          <Button
            onClick={(event) => {
              addAnotherInstructions(event);
            }}
            sx={{ my: 1, width: "100%" }}
          >
            Add another
          </Button>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
            }}
          >
            <div style={{ width: "100%" }}>
              <TextField
                id="img"
                name="img"
                label="https://..."
                value={imageSrc}
                onChange={(event) => setImageSrc(event.target.value)}
                sx={{ width: "100%" }}
              />
              <Button
                onClick={(event) => {
                  setaddedImg(imageSrc);
                }}
                sx={{ my: 1, width: "100%" }}
              >
                Add Image
              </Button>
            </div>

            {addedImg && (
              <img
                src={addedImg}
                alt={"img"}
                style={{
                  width: 500,
                  height: 400,
                }}
                loading="lazy"
              />
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
            }}
          >
            <TextField
              id="img"
              name="img"
              label="Url Link"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
            }}
          >
            <TextField
              id="calori"
              name="calori"
              label="Calori (80/kcal)"
              value={calori}
              onChange={(event) => setCalori(event.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
            }}
          >
            <TextField
              id="Video"
              name="Video"
              label="Video Link (https://..)"
              value={video}
              onChange={(event) => setVideo(event.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
            }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder={`"16 gram karbonhidrat", "28 gram protein","32 gram yağ"
                `}
              style={{ width: "100%" }}
              onChange={(event) => setNutritions(event.target.value)}
            />
          </Stack>
        </Stack>
        <Button
          variant="contained"
          onClick={(event) => {
            SendRecipe();
          }}
          sx={{ my: 1, width: "100%" }}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
}
