import React, { useState } from "react";
import Button from "@mui/material/Button";
import Scrapper from "../components/urlScrapper";
import RecipeEntry from "../components/recipeEntry";

export default function addRecipe() {
  const [type, setType] = useState(null);
  return (
    <>
      {!type && (
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 500,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={(event) => setType("url")}
            sx={{ m: 2 }}
          >
            Create Recipe (Yemek.com)
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={(event) => setType("manuel")}
            sx={{ my: 1 }}
          >
            Create Recipe (manuel)
          </Button>
        </div>
      )}
      {type && type == "url" && <Scrapper />}
      {type && type == "manuel" && <RecipeEntry />}
    </>
  );
}
