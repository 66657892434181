import React, { useState } from "react";
import { navigate } from "gatsby";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Board from "../components/Weekdaydnd/Board";
import { useMutation, useQuery } from "@apollo/client";
import { GET_WEEKLYPLANS, ADD_MEALPLAN_MUTATION } from "../graphql/mealplan";
import { GET_INGREDIENTSDATA } from "../graphql/ingredients";
import Stage1 from "../components/MealPlan/Stage1";
import FullPageLoader from "../components/fullPageLoader";
import { getUser } from "../services/auth";
import { prepareIngredientList, makeAddPlanDBData } from "../utils/fns";
import Ingredients from "../components/MealPlan/Ingredients";
import {
  convertLineUnits,
  makeIngredientListFromRecipes,
  Pretty,
  totalIngAmount,
} from "../utils/fns2";

const AddMealPlan = (props) => {
  const user = getUser();
  const [page, setpage] = useState(0);
  const [boardData, setBoardData] = useState(null);
  const [newBoard, setNewBoard] = useState(null);
  const [ingredientList, setingredientList] = useState(null);

  const plans = useQuery(GET_WEEKLYPLANS, {
    variables: { uid: user.id },
  });
  const { data: ingData } = useQuery(GET_INGREDIENTSDATA);
  const [AddMealPlan, { loading, error: error2 }] = useMutation(
    ADD_MEALPLAN_MUTATION,
    {
      onCompleted: ({ AddMealPlanMutation }) => {
        // navigate("/app/mealplans");
      },
      refetchQueries: [
        GET_WEEKLYPLANS, // DocumentNode object parsed with gql
      ],
    }
  );

  function onCreateBoard(data) {
    setBoardData(data);
    setpage(1);
    window.scrollTo(0, 0);
  }

  // function goPageTwo() {
  //   const newP2 = prepareIngredientList(newBoard);
  //   setingredientList(newP2);
  //   setpage(2);
  // }

  async function onCreatePlan() {
    await AddMealPlan({
      variables: {
        data: makeAddPlanDBData(newBoard, boardData, user, null),
      },
    });
    setpage(2);
    CalculateIngredients()
  }

  function onLoadData(data) {
    let boardD = { ...data.board };
    setBoardData({ ...data, board: boardD });
    setNewBoard({ quoteMap: boardD });
    setpage(1);
    window.scrollTo(0, 0);
  }
  async function CalculateIngredients() {
    let recipes = []
    Object.values(boardData.board).forEach(s=>{
      recipes = [...recipes, ...s]
    })
    const { lineunitlist, ingredientsMatch } = ingData.ingredientsDatum;
    const ingredients = makeIngredientListFromRecipes(recipes);
    const { conversionObj, ingredientunits } = convertLineUnits(lineunitlist);
    const [prettySorted, uniqueUnits] = await Pretty(
      ingredients,
      conversionObj
    );
    const calculateTotalAmount = totalIngAmount(
      prettySorted,
      ingredientsMatch,
      conversionObj,
      ingredientunits
    );
    setingredientList(calculateTotalAmount)
  }

  return (
    <>
      {page == 0 && (
        <Stage1 setBoardData={onCreateBoard} setDummyData={onLoadData} />
      )}
      {page == 1 && boardData && (
        <>
          <Board
            initial={boardData.board}
            ogunTags={boardData.times}
            weekTags={boardData.weekTags}
            setNewBoard={(d) => setNewBoard(d)}
          />
          <Stack
            direction="row-reverse"
            spacing={1}
            sx={{
              p: 2,
              m: 2,
              border: "1px dashed grey",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={(event) => onCreatePlan()}
              sx={{ my: 1 }}
            >
              Create
            </Button>
          </Stack>
        </>
      )}
      {page == 2 && (
        <>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            spacing={1}
            sx={{ p: 2, m: 2, border: "1px dashed grey" }}
          >
            <div>Meal Plan Created!</div>
            <Button
              variant="contained"
              onClick={(event) => navigate('/app/calendar')}
              sx={{ my: 1 }}
            >
              Go Calendar
            </Button>
          </Stack>
          <Stack spacing={1} sx={{ p: 2, m: 2, border: "1px dashed grey" }}>
            {ingredientList && <Ingredients list={ingredientList} />}
          </Stack>
        </>
      )}
      {loading && <FullPageLoader />}
    </>
  );
};

export default AddMealPlan;
