import React from "react";
import { graphql } from "gatsby";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import Markdown from "react-markdown";
import { useQuery } from "@apollo/client";
import { GET_RECIPE } from "../graphql/recipes";
import Recipe from "../components/recipe";
import Ingredients from "../components/Ingredients";
import { GET_INGREDIENT } from "../graphql/ingredients";


const SingleIngredient = ({ slug }) => {
  const { loading, error, data } = useQuery(GET_INGREDIENT, {
    variables: {
      id: slug,
    },
  });
  // console.log('data',data);

  if (loading || !data) return <div>loading...</div>;
  return (
    <Container maxWidth="lg" style={{ padding: 10 }}>
      <Box>
        <Ingredients data={data.ingredient} />
      </Box>
    </Container>
  );
};

export default SingleIngredient;
