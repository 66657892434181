import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { RenderPlanImages } from "../screens/mealplans";
import { navigate } from "gatsby";
import { getUser } from "../services/auth";
import Ingredients from "../components/MealPlan/Ingredients";
import { useQuery } from "@apollo/client";
import { GET_RECIPES, GET_RECIPE_TAGS } from "../graphql/recipes";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function AddRecipe({ open, handleClose, onAddRecipe, id }) {
  const user = getUser();
  const { loading, error, data } = useQuery(GET_RECIPES, {
    variables: [],
  });
  // console.log("data", data);
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        Recipes
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ width: 550, height: 300 }}>
        {/* <Ingredients list={list} /> */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={data.recipes}
          getOptionLabel={(option) => option.recipeTitle}
          sx={{ width: 530, height: 200 }}
          renderInput={(params) => <TextField {...params} label="Tarif" />}
          onChange={(event, newValue) => {
            onAddRecipe(newValue, id)
            handleClose()
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`${option.imageSrc}`}
                srcSet={`${option.imageSrc}`}
                alt=""
              />
              {option.recipeTitle}
            </Box>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
