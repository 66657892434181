// @flow
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { grid } from "./constants";

// $ExpectError - not sure why
export default styled.h5`
  padding: 10px;
  margin-bottom:10px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  border-bottom: 1px solid #ccc;

  &:focus {
    outline: 2px solid ${colors.P100};
    outline-offset: 2px;
  }
`;
