import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { navigate } from "gatsby";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function IngTooltips({ ids, list }) {
  const id = ids[0];
  const ing = list.filter((s) => s.id == id);
  if (ing.length == 0) return null;
  const { id: cid, name, image, price } = ing[0];
  if (price) {
    let link = price.offers[0].offers[0].href;
    if (link[0] == "/") link = "https://www.cimri.com" + link;
  }

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              src={image}
              style={{
                width: 60,
                height: 60,
                objectFit: "cover",
                marginRight: 15,
              }}
            />
            <div>
              <Typography
                color="inherit"
                style={{ textTransform: "capitalize" }}
              >
                {name}
              </Typography>
              {/* <em>{"And here's"}</em> <b>{"some"}</b> <u>{"amazing content"}</u>.{" "}
          {"It's very engaging. Right?"} */}
              <span>
                {/* <a href={link} target="_blank">
                  {price.pricetoUse}{" "}
                </a>{" "} */}
                <a
                  onClick={() => navigate(`/app/ingredient/` + cid)}
                  target="_blank"
                >
                  {price?.pricetoUse}{" "}
                </a>{" "}
              </span>
            </div>
          </div>
        </React.Fragment>
      }
    >
      {/* <Button>HTML</Button> */}
      <img
        src={image}
        style={{ width: 20, height: 20, objectFit: "cover" }}
        onClick={() => navigate(`/app/ingredient/` + cid)}
      />
    </HtmlTooltip>
  );
}
