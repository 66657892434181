import React from "react";

export default function Ingredient({ data }) {
  // aliases: ['sumak']
  // definedUnits: [{…}]
  // id: "91"
  // image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5y9VYUL24RvVjCtJQhlr6S4nuY9FtPnaJQ-onsKcRVWrg2n8Dgfi5maWmOX8&s"
  // key: "sumak"
  // name: "sumak"
  // nutritions: null
  // price: {pricetoUse: '23,49 TL/kg', offers: Array(20)}
  // recurrenceStr: "17"
  // updated_at: "2022-02-24T13:39:12.058Z"
  // console.log(`data`, data);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirectioon: "row",
          justifyContent:'space-between'
        }}
      >
        {data.image && <img
          src={`${data.image}?w=248&fit=crop&auto=format`}
          srcSet={`${data.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={data.name}
          loading="lazy"
          style={{ width: 200, height: 200 }}
        />}
        <div>
          <div>Name: {data.name}</div>
          <div>Alias: {data.aliases.join(",")}</div>

          <div>
            recurrenceStr:
            {data.recurrenceStr}
          </div>
          <div>
            UpdatedAt:
            {data.updated_at}
          </div>
          <div>
            pricetoUse:
            {data?.price?.pricetoUse}
          </div>
        </div>
        <div>
          {data.definedUnits && <div>
            <span>Defined Units</span>
            {data.definedUnits.map((s) => {
              return <li key={s.fullText}>{s.fullText}</li>;
            })}
          </div>}
        </div>
      </div>
      <div>
        <h4>Offers</h4>
        {data?.price?.offers.map((s, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirectioon: "row",
                marginBottom:20
              }}
            >
              <img
                src={s.img}
                srcSet={s.img}
                alt={s.title}
                loading="lazy"
                style={{ width: 50, height: 50 }}
              />
              <div>
                <div>Title: {s.title}</div>
                <div>Price: {s.pricetoUse}</div>
                <div>
                  {s.offers.map((t, j) => {
                    return (
                      <div key={j}>
                        <a
                          href={t.href[0] == '/' ? 'https://cimri.com'+t.href : t.href}
                          target='_blank'
                        >{`${t.origin.indexOf('data') < -1 ? t.origin : ''} ${t.price} ${t.pricekg}`}</a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
