import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IngTooltips from "./ingTooltip";
import { Button } from "@mui/material";
import { navigate } from "gatsby";

export default function Recipe({ data }) {
  // console.log("Recipe data", data);
  function renderIngredients(){
    if (data.ingredients[0].list){
      return data.ingredients.map((S, I) => (
        <Typography key={I} variant="h9" component="div" sx={{}}>
          {S.name && (
            <Typography variant="h5" component="div" sx={{}}>
              {S.name}
            </Typography>
          )}
          {S.list.map((s, i) => (
            <li key={i}>
              <span>
                <span style={{ marginRight: 15 }}>{s.details.join(" ")}</span>
                {data.ingredientObjs && (
                  <IngTooltips ids={s.ingIds} list={data.ingredientObjs} />
                )}
              </span>
            </li>
          ))}
        </Typography>
      ));
    }else{
      return (<Typography variant="h9" component="div" sx={{}}>
          {data.ingredients.map((s, i) => (
            <li key={i}>
              <span>
                <span style={{ marginRight: 15 }}>{s.details.join(" ")}</span>
                {data.ingredientObjs && (
                  <IngTooltips ids={s.ingIds} list={data.ingredientObjs} />
                )}
              </span>
            </li>
          ))}
        </Typography>
      );
    }
  }

  return (
    <Box
      sx={{
        my: 4,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={data.imageSrc}
        loading="lazy"
        alt=""
        style={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
        sx={{ mx: 2 }}
      />

      {/* <Typography variant="h7" noWrap component="div" sx={{ flex: 1 }}>
          {recipe.title}
        </Typography> */}

      <Typography variant="h4" component="div">
        {data.recipeTitle}
      </Typography>

      <div
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "space-between",
          justifyContent: "space-around",
          marginBottom: 20,
        }}
      >
        {data.details.map((s, i) => (
          <Typography key={i} variant="h9" component="span" sx={{ mr: 5 }}>
            {s.text} - {s.number}
          </Typography>
        ))}

        {data.recipe_tags && (
          <>
            {data.recipe_tags.map((s, i) => (
              <Typography key={i} variant="h9" component="span" sx={{ mr: 1 }}>
                <Button onClick={() => navigate("/app/recipes?tag=" + s.id)}>
                  #{s.name}
                </Button>
              </Typography>
            ))}
          </>
        )}
      </div>

      <div>
        {data.video && (
          <div
            style={{
              width: 940,
              height: 426,
              backgroundColor: "rgba(0,0,0,0.7)",
              position: "relative",
            }}
          >
            <iframe width="940" height="426" src={data.video}></iframe>
            {/* <video
              controls
              name="media"
              width="620"
              height="426"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            >
              <source src={data.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
          </div>
        )}
      </div>

      <div style={{ marginBottom: 20 }}>
        <Typography variant="h5" component="div" sx={{}}>
          Malzemeler
        </Typography>
        {renderIngredients()}
      </div>

      <div style={{ marginBottom: 20 }}>
        <Typography variant="h4" component="div" sx={{ mb: 4 }}>
          Nasil Yapilir
        </Typography>

        <Typography variant="h9" component="div" sx={{}}>
          {data.instructions.map((s, i) => (
            <div key={i} style={{ marginBottom: 20 }}>
              <li
                style={{ marginBottom: 10, fontSize: 18 }}
                dangerouslySetInnerHTML={{
                  __html: s.text,
                }}
              ></li>
              {s.img && (
                <img
                  src={s.img}
                  alt=""
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                />
              )}
            </div>
          ))}
        </Typography>
      </div>

      {data.nutritions.length > 0 && (
        <div style={{ marginBottom: 20 }}>
          <Typography variant="h5" component="div" sx={{}}>
            Nutritions
          </Typography>
          <Typography variant="h9" component="div" sx={{}}>
            {data.nutritions.map((s, i) => (
              <div key={i} style={{ marginBottom: 10 }}>
                <li>{s}</li>
              </div>
            ))}
          </Typography>
        </div>
      )}

      {data.calori && (
        <div style={{ marginBottom: 20 }}>
          <Typography variant="h5" component="div" sx={{}}>
            Calori: {data.calori}
          </Typography>
        </div>
      )}
      {data.cost && <div>Est. Cost: {data.cost} TL</div>}

      <Typography variant="h9" noWrap component="div">
        <a href={data.url}>{data.url}</a>
      </Typography>
    </Box>
  );
}
