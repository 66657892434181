import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function loadingPage() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: '500px'
      }}
    >
      <CircularProgress color="success" />
    </div>
  );
}
