import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useQuery } from "@apollo/client";
import { GET_RECIPES, GET_RECIPE_TAGS } from "../graphql/recipes";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import LoadingPage from '../components/loadingPage'

var stringToColor = (string, saturation = 100, lightness = 75) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};



const Recipes = (props) => {
  const [tag, setTag] = useQueryParam("tag", StringParam);
  let queryVariables = null;
  if (tag) {
    console.log("tag", tag);
    queryVariables = {
      input: { recipe_tags: { id: tag } },
    };
  }
  const { loading, error, data } = useQuery(GET_RECIPES, {
    variables: queryVariables,
  });
  const { data: recipeTagsData } = useQuery(GET_RECIPE_TAGS);
  // console.log('data',data);

  if (loading) return <LoadingPage />;

  const recipesSorted = [...data.recipes].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <Container maxWidth="lg" style={{ padding: 10 }}>
      <Box>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 2,
            m: 2,
            border: "1px dashed grey",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={data.recipes}
            getOptionLabel={(option) => option.recipeTitle}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Tarif" />}
            onChange={(event, newValue) => {
              navigate("/app/recipe/" + newValue.id);
            }}
          />
          <Button
            onClick={(event) => navigate("/app/recipe/add")}
            sx={{ my: 1 }}
          >
            Create Recipe
          </Button>
        </Stack>

        {recipeTagsData?.recipeTags.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 10,
              flexWrap: "wrap",
              // overflowY: "scroll",
            }}
          >
            {[...recipeTagsData?.recipeTags]
              .sort((a, b) => b.recipes.length - a.recipes.length)
              .map((s, i) => {
                return (
                  <div key={"tags" + i}>
                    <div
                      style={{
                        border: "1px solid rgba(0,0,0,0.1)",
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                        backgroundColor: stringToColor(s.name),
                      }}
                    >
                      <a
                        onClick={() => navigate(`/app/recipes?tag=${s.id}`)}
                        style={{ color: "rgba(0,0,0,0.5)" }}
                      >{`${s.name.toLocaleLowerCase("tr-TR")} (${
                        s.recipes.length
                      })`}</a>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <ImageList cols={3} gap={8}>
            {recipesSorted.map((item) => (
              <ImageListItem
                key={item.id + "recipes"}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(`/app/recipe/` + item.id);
                }}
              >
                <img
                  src={`${item.imageSrc}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.recipeTitle}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <ImageList cols={2} gap={4}>
            {data.recipes.map((item) => (
              <ImageListItem
                key={item.id + "recipes"}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(`/app/recipe/` + item.id);
                }}
              >
                <img
                  src={`${item.imageSrc}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.recipeTitle}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Box>
    </Container>
  );
};

export default Recipes;
