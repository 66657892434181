// @flow
import React, { useState, useEffect, Component } from "react";
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import QuoteList from "./quote-list";
import AuthorList from "./author-list";
import { grid } from "./constants";
import { reorderQuoteMap } from "./reorder";
import Title from "./title";
import AddRecipeDialog from "../../modals/AddRecipe";

const Root = styled.div`
  /* box-sizing: border-box; */
  /* padding: ${grid * 2}px; */
  min-height: 80vh;
  /* flexbox */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* overflow: scroll; */
`;

const Column = styled.div`
  margin: 0 ${grid * 2}px;
`;

const HorizontalScrollContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* background: rgba(0, 0, 0, 0.1); */
  padding: ${grid}px;
  max-width: 100%;
  overflow: auto;
`;

const VerticalScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.1);
  padding: ${grid}px;
  max-height: 800px;
  overflow: auto;
`;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function Board({ initial, ogunTags, weekTags, setNewBoard, plan }) {
  // initial={boardData.board}
  // ogunTags={boardData.times}
  // weekTags={boardData.weekTags}
  const [boardData, setBoardData] = useState(null);
  const [eventClickState, setEventClickState] = useState(null);
  useEffect(() => {
    setBoardData(initial);
  }, [initial]);

  function onClickAdd(x, y) {
    setEventClickState({ x, y });
  }
  function onAddRecipe(recipe, id) {
    const { x, y } = id;
    let key = `${y}.${x.name}`;
    const oldRecipes = boardData[key];
    let newRecipes = oldRecipes;
    if (oldRecipes.map((o) => o.id).indexOf(recipe.id) < 0) {
      newRecipes = [...oldRecipes, recipe];
    }
    const newBoard = { ...boardData, [key]: newRecipes };
    setBoardData(newBoard);
    setNewBoard({quoteMap:newBoard})
  }
  function onClickRemove(data, x, y) {
    let recipeId = data.id;
    let key = `${y}.${x.name}`;
    const oldRecipes = boardData[key];
    const newBoard = {
      ...boardData,
      [key]: oldRecipes.filter((o) => o.id != recipeId),
    };
    setBoardData(newBoard);
    setNewBoard({quoteMap:newBoard})
  }
  return (
    <Root>
      {boardData && (
        <HorizontalScrollContainer>
          {weekTags.map((w, i) => {
            return (
              <div key={i}>
                <div style={{ textAlign: "center" }}>{w.toUpperCase()}</div>
                <Column>
                  {ogunTags.map((o, j) => {
                    return (
                      <QuoteList
                        key={w + "." + o.name}
                        title={o.name}
                        x={o}
                        y={w}
                        listId={w + "." + o.name}
                        listType="card"
                        quotes={boardData[w + "." + o.name]}
                        onClickAdd={onClickAdd}
                        onClickRemove={onClickRemove}
                      />
                    );
                  })}
                </Column>
              </div>
            );
          })}
        </HorizontalScrollContainer>
      )}
      {eventClickState && (
        <AddRecipeDialog
          open={true}
          id={eventClickState}
          onAddRecipe={onAddRecipe}
          handleClose={() => setEventClickState(null)}
        />
      )}
    </Root>
  );
}
