import { gql } from "@apollo/client";
import { FRAGMENT_RECIPE_SC } from "./recipes";

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        username
        email
        id
        confirmed
        blocked
      }
    }
  }
`;

export const SCRAPPER_PARSE_URL_MUTATION = gql`
  mutation ScrapperMutation($url: String!) {
    ScrapperMutation(url: $url) {
      ...RecipedetailsSC
    }
  }
  ${FRAGMENT_RECIPE_SC}
`;
