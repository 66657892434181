import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Board from "../components/Weekdaydnd/Board";
import { useMutation, useQuery } from "@apollo/client";
import { GET_WEEKLYPLANS, EDIT_MEALPLAN_MUTATION } from "../graphql/mealplan";
import { GET_INGREDIENTSDATA } from "../graphql/ingredients";
import Stage1 from "../components/MealPlan/Stage1";
import FullPageLoader from "../components/fullPageLoader";
import { getUser } from "../services/auth";
import { prepareIngredientList, makeAddPlanDBData } from "../utils/fns";
import Ingredients from "../components/MealPlan/Ingredients";
import {
  convertLineUnits,
  makeIngredientListFromRecipes,
  Pretty,
  totalIngAmount,
} from "../utils/fns2";

const EditMealPlan = ({ slug }) => {
  const user = getUser();
  const { data } = useQuery(GET_WEEKLYPLANS, {
    variables: { uid: user.id },
  });
  const { data: ingData } = useQuery(GET_INGREDIENTSDATA);
  let plan = data ? data.weeklyplans.filter((s) => s.id == slug)[0] : null;
  const [page, setpage] = useState(0);
  const [boardData, setBoardData] = useState(null);
  const [newBoard, setNewBoard] = useState(null);
  const [ingredientList, setingredientList] = useState(null);

  const [AddMealPlan, { loading, error: error2 }] = useMutation(
    EDIT_MEALPLAN_MUTATION,
    {
      onCompleted: ({ AddMealPlanMutation }) => {
        // navigate("/app/mealplans");
        // console.log('AddMealPlanMutation',AddMealPlanMutation);
      },
      refetchQueries: [
        GET_WEEKLYPLANS, // DocumentNode object parsed with gql
      ],
    }
  );

  function onCreateBoard(data) {
    setBoardData(data);
    if (plan && !newBoard) {
      setNewBoard({ quoteMap: data.board });
    }
    setpage(1);
    window.scrollTo(0, 0);
  }

  async function onCreatePlan() {
    const data = makeAddPlanDBData(newBoard, boardData, user, null);
    await AddMealPlan({
      variables: {
        data,
        id: plan.id,
      },
    });
    setpage(2);
    CalculateIngredients()
  }

  async function CalculateIngredients() {
    let recipes = []
    Object.values(boardData.board).forEach(s=>{
      recipes = [...recipes, ...s]
    })
    const { lineunitlist, ingredientsMatch } = ingData.ingredientsDatum;
    const ingredients = makeIngredientListFromRecipes(recipes);
    const { conversionObj, ingredientunits } = convertLineUnits(lineunitlist);
    const [prettySorted, uniqueUnits] = await Pretty(
      ingredients,
      conversionObj
    );
    const calculateTotalAmount = totalIngAmount(
      prettySorted,
      ingredientsMatch,
      conversionObj,
      ingredientunits
    );
    setingredientList(calculateTotalAmount)
  }

  if (!data) return null;
  return (
    <>
      {page == 0 && <Stage1 setBoardData={onCreateBoard} plan={plan} />}
      {page == 1 && boardData && (
        <>
          
          <Board
            plan={plan}
            initial={boardData.board}
            ogunTags={boardData.times}
            weekTags={boardData.weekTags}
            setNewBoard={(d) => setNewBoard(d)}
          />
          <Stack
            direction="row-reverse"
            spacing={1}
            sx={{ p: 2, m: 2, border: "1px dashed grey" }}
          >
            <Button
              variant="contained"
              onClick={(event) => onCreatePlan()}
              sx={{ my: 1 }}
            >
              Edit Weekly Plan
            </Button>
          </Stack>
        </>
      )}
      {page == 2 && (
        <>
          {/* <Stack
            direction="row-reverse"
            spacing={1}
            sx={{ p: 2, m: 2, border: "1px dashed grey" }}
          >
            <Button
              variant="contained"
              onClick={(event) => CalculateIngredients()}
              sx={{ my: 1 }}
            >
              Calculate Ingredients
            </Button>
          </Stack> */}
          <Stack spacing={1} sx={{ p: 2, m: 2, border: "1px dashed grey" }}>
            {ingredientList && <Ingredients list={ingredientList} />}
          </Stack>
        </>
      )}
      {loading && <FullPageLoader />}
    </>
  );
};

export default EditMealPlan;
