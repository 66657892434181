import React, { useState } from "react";
import { navigate } from "gatsby";
import Button from "@mui/material/Button";
import { useMutation, useQuery } from "@apollo/client";
import { GET_WEEKLYPLANS, DELETE_MEALPLAN_MUTATION } from "../graphql/mealplan";
import { GET_INGREDIENTSDATA } from "../graphql/ingredients";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { getUser } from "../services/auth";
import ShowIngredients from "../modals/ShowIngredients";
import { GET_CALENDARS } from "../graphql/calendar";
import {
  convertLineUnits,
  makeIngredientListFromRecipes,
  Pretty,
  totalIngAmount,
} from "../utils/fns2";
import LoadingPage from "../components/loadingPage";

export function RenderPlanImages({ recipes }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {recipes
        ?.map((b) => b.imageSrc)
        .map((s, k) => {
          return (
            <img
              key={k}
              src={`${s}?w=248&fit=crop&auto=format`}
              srcSet={`${s}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt=""
              loading="lazy"
              style={{
                width: 20,
                height: 20,
              }}
            />
          );
        })}
    </div>
  );
}

const Mealplans = (props) => {
  const user = getUser();
  const [inglist, setInglist] = useState(null);
  const { loading: loading1, error, data } = useQuery(GET_WEEKLYPLANS, {
    variables: { uid: user.id },
  });
  const { data: ingData, loading: loading2 } = useQuery(GET_INGREDIENTSDATA);
  const { data: cDatas, loading: loading3 } = useQuery(GET_CALENDARS, {
    variables: { uid: user.id },
  });
  let calendarAddedWeeklyPlans = [];
  if (cDatas) {
    cDatas.calendars.forEach((f) => {
      f.weeklyplans.forEach((s) => {
        calendarAddedWeeklyPlans.push(s.id);
      });
    });
  }

  const [DeleteMealPlan, { loading: loading4, error: error2 }] = useMutation(
    DELETE_MEALPLAN_MUTATION,
    {
      onCompleted: (data) => {
        console.log(data);
      },
      refetchQueries: [
        GET_WEEKLYPLANS, // DocumentNode object parsed with gql
      ],
    }
  );

  async function removePlan(id) {
    if (calendarAddedWeeklyPlans.indexOf(id) > -1) {
      let name = data.weeklyplans.filter((d) => d.id == id)[0].name;
      alert(`Weeklyplan ${name} used in calendar, please remove it first`);
    } else {
      DeleteMealPlan({
        variables: {
          id,
        },
      });
    }
  }

  async function CalculateIngredients(d) {
    let recipes = [];
    d.baskets.forEach((b) => {
      recipes = [...recipes, ...b.meals];
    });
    let recipesFull = [];
    recipes.forEach((el) => {
      recipesFull.push(d.recipes.filter((r) => r.id == el)[0]);
    });
    const { lineunitlist, ingredientsMatch } = ingData.ingredientsDatum;
    const ingredients = makeIngredientListFromRecipes(recipesFull);
    const { conversionObj, ingredientunits } = convertLineUnits(lineunitlist);
    const [prettySorted, uniqueUnits] = await Pretty(
      ingredients,
      conversionObj
    );
    const calculateTotalAmount = totalIngAmount(
      prettySorted,
      ingredientsMatch,
      conversionObj,
      ingredientunits
    );
    setInglist(calculateTotalAmount);
  }

  if (loading1 || loading2 || loading3) return <LoadingPage />;
  if (!data) return null;

  return (
    <Container maxWidth="lg" style={{ padding: 10 }}>
      <Box>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 1,
            m: 1,
            border: "1px dashed grey",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" noWrap component="div">
            Weekly Meal Plans
          </Typography>
          <Button
            primary="true"
            onClick={(event) => navigate(`/app/mealplans/add`)}
            sx={{ my: 1 }}
          >
            Add Meal Plan
          </Button>
        </Stack>

        <div
          style={{
            overflow: "scroll",
            width: "100%",
          }}
        >
          <Box sx={{ minWidth: 700 }}>
            <Stack
              spacing={1}
              sx={{
                p: 1,
                m: 1,
                border: "1px dashed grey",
              }}
            >
              <Grid container spacing={0.5}>
                <Grid item xs={2}>
                  <Typography variant="h9" noWrap component="div">
                    Plan Name
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h9" noWrap component="div">
                    Times
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h9" noWrap component="div">
                    Weekdays
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h9" noWrap component="div">
                    Ingredients
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h9" noWrap component="div">
                    Pictures
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h9" noWrap component="div">
                    Delete
                  </Typography>
                </Grid>
              </Grid>

              {data.weeklyplans.map((d, i) => {
                return (
                  <div key={"grid" + i}>
                    <ListItemButton
                      onClick={(event) => {
                        event.preventDefault();
                        navigate(`/app/mealplans/edit/` + d.id, {
                          state: { d },
                        });
                      }}
                    >
                      <Grid container spacing={0.5}>
                        <Grid item xs={2}>
                          <Typography variant="h9" noWrap component="div">
                            {d.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="h9" noWrap component="div">
                            {d.times.map((t, j) => (
                              <span key={j}>
                                {t.name}({t.time}),{" "}
                              </span>
                            ))}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h9" noWrap component="div">
                            {d.weekdays?.join(",")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // setInglist(d.ingredientlist);
                              CalculateIngredients(d);
                            }}
                          >
                            <FormatListBulletedIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                          <RenderPlanImages recipes={d.recipes} />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              removePlan(d.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                    <Divider />
                  </div>
                );
              })}
            </Stack>
          </Box>
        </div>
      </Box>
      {inglist && (
        <ShowIngredients
          list={inglist}
          open={true}
          handleClose={() => setInglist(null)}
        />
      )}
    </Container>
  );
};

export default Mealplans;
