import React from "react";
import { graphql } from "gatsby";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import Markdown from "react-markdown";
import { useQuery } from "@apollo/client";
import { GET_RECIPE } from "../graphql/recipes";
import Recipe from "../components/recipe";
import LoadingPage from '../components/loadingPage'

const SingleRecipe = ({ slug }) => {
  const { loading, error, data } = useQuery(GET_RECIPE, {
    variables: {
      id: slug,
    },
  });

  if (loading) return <LoadingPage />;
  return (
    <Container maxWidth="lg" style={{ padding: 10 }}>
      <Box>
        <Recipe data={data.recipe} />
      </Box>
    </Container>
  );
};

export default SingleRecipe;
