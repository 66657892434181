// @flow
import React from "react";
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { grid } from "./constants";
import QuoteItem from "./quote-item";
import Title from "./title";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return colors.R50;
  }
  if (isDraggingFrom) {
    return colors.T50;
  }
  return colors.N30;
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
`;

const scrollContainerHeight = 300;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

const InnerQuoteList = React.memo(function InnerQuoteList(props) {
  return props.quotes.map((quote, index) => (
    <div key={'recipe'+quote.id} index={index}>
      <QuoteItem quote={quote} onClickRemove={props.onClickRemove}/>
    </div>
  ));
});

function InnerList(props) {
  const { quotes, dropProvided, onClickRemove } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;
  return (
    <Container>
      {title}
      <DropZone>
        <InnerQuoteList quotes={quotes} onClickRemove={(item)=>onClickRemove(item, props.x, props.y)}/>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 0,
            border: "1px dashed grey",
            justifyContent: "center",
            background: "#fff",
          }}
          onClick={() => props.onClickAdd(props.x, props.y)}
        >
          <IconButton aria-label="delete">
            <AddBoxIcon fontSize={"small"} />
          </IconButton>
        </Stack>
      </DropZone>
    </Container>
  );
}

export default function QuoteList(props) {
  const {
    internalScroll,
    scrollContainerStyle,
    style,
    quotes,
    title,
    x,
    y,
    onClickAdd,
    onClickRemove
  } = props;

  return (
    <Wrapper style={style}>
      {internalScroll ? (
        <ScrollContainer style={scrollContainerStyle}>
          <InnerList
            quotes={quotes}
            title={title}
            x={x}
            y={y}
            onClickAdd={onClickAdd}
          />
        </ScrollContainer>
      ) : (
        <InnerList
          quotes={quotes}
          title={title}
          x={x}
          y={y}
          onClickAdd={onClickAdd}
          onClickRemove={onClickRemove}
        />
      )}
    </Wrapper>
  );
}
